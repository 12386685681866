<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <!-- <button *ngIf="!isHTML" type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div class="modal-body">
    <p *ngIf="!isHTML">{{ content }}</p>
    <p *ngIf="isHTML" [innerHtml]="content"></p>
</div>
<div class="modal-footer">
    <button *ngFor="let button of buttonsConfig" type="button" [ngClass]="button.class" 
      (click)="button.action()">{{ button.label }}</button>
    <button *ngIf="!isHTML" type="button" (click)="closeModal()" class="btn btn-light">{{'lang.cancel'| translate }}</button>  
</div>
