import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions  } from 'ngx-bootstrap/modal';
import { ModalComponent } from './modal/modal.component'; 

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  modalRef!: BsModalRef;

  constructor(private modalService: BsModalService) { }

  openModal(title: string, content: string,modalConfig: ModalOptions,isHTML:boolean, buttonsConfig: any, onClose?: () => void, onShow?: () => void): void {
    const initialState = {
      title,
      content,
      isHTML,
      buttonsConfig,
      onClose,
      onShow
    }; 
    const config = {
      initialState,
      ...modalConfig
    };
    this.modalRef = this.modalService.show(ModalComponent, config );
  }

  closeModal(): void {
    if (this.modalRef) {
      this.modalRef.hide();
    }
  }
}
