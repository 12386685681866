<div class="container mt-5">

    <p-panel header="{{'lang.devices_tab' |translate}}">
      <!-- <p> -->
        <!-- <h5 class="bold fl-color p-2"> {{'lang.heading_welcome'| translate}}</h5> -->
        <form *ngIf="loadTable">
            <!-- <div class="row col-sm-5 col-md-5 col-xs-12 p-2" >
                <div class="input-group">
                  <input type="text" class="form-control" [(ngModel)]="search" name="search" *ngIf="rows?.length > 4"/>
                  <span class="input-group-text" *ngIf="search" (click)="reset()" role="button" tooltip="{{'lang.cancel' | translate}}" >
                    <i class='fa fa-times'></i>
                  </span>  
                </div>
            </div> -->
            <div *ngIf="returnRow">
              <p class="ms-3 small">{{rows?.length}}  {{ 'lang.devices_found'| translate }}</p>
              <p-divider></p-divider>
              <div class="table-responsive" *ngIf="rows?.length > 0">
                <table class="table">

                <thead>
                    <tr>
                    <th>{{ 'lang.name'| translate }}</th>
                    <th (click)="sortBy('serial_number')" role="button" class="link-info" >{{ 'lang.device_serial_number'| translate }}
                        <i [class]="currentSortColumn === 'serial_number' ? (currentSortDirection === 'asc' ? 'fas fa-arrow-up' : 'fas fa-arrow-down') : ''"></i>
                    </th>
                    <th (click)="sortBy('device_type')"  role="button" class="link-info">{{ 'lang.type'| translate }}
                        <i [class]="currentSortColumn === 'device_type' ? (currentSortDirection === 'asc' ? 'fas fa-arrow-up' : 'fas fa-arrow-down') : ''"></i>
                    </th>
                    <th>{{ 'lang.user'| translate }}</th>
                    <th>{{ 'lang.owner'| translate }}</th>
                    <th (click)="sortBy('created_at')"  role="button" class="link-info">{{ 'lang.registered'| translate }}
                        <i [class]="currentSortColumn === 'created_at' ? (currentSortDirection === 'asc' ? 'fas fa-arrow-up' : 'fas fa-arrow-down') : ''"></i>
                    </th>
                    <!-- <th>Actions</th> -->
                    </tr>
                </thead>
                  <tbody>
                    <ng-container *ngFor="let device of returnRow ; let i = index" >
                      <tr >
                        <td>{{device?.module_label}}</td>
                        <td role="button" class="link-info">{{device?.serial_number}}</td>
                        <td>{{device?.device_type}}</td>
                        <td>{{device?.user}}</td>
                        <td>{{device?.master_user}}</td>
                        <td>{{device?.created_at | date: 'M/dd/yyyy'}}</td>
                        <td>
                            <button class="btn btn-danger btn-sm fa fa-trash" tooltip="{{'lang.remove_device' | translate}}" (click)="openModal(device)"></button>
                        </td>

                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <div class="table-responsive" *ngIf="rows?.length < 1">
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <td>No Devices found.</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- {{result?.count > 5}} {{count}} {{rows?.length}} {{currentPage}} {{pageSize}} {{result.count > 5 && rows?.length > pageSize}} {{!search != ''}} -->
              <div class="d-flex justify-content-between" *ngIf="rows.count > 10"> 
                <pagination [totalItems]="count" class="pagination"
                [itemsPerPage]="pageSize" 
                [boundaryLinks]="true"
                previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                [(ngModel)]="currentPage"
                name="currentPage"
                (click)="pageChanged(currentPage)"></pagination>
              </div>
            </div>
            </form>
            <div class="d-flex justify-content-center" *ngIf="!loadTable">
              <div class="spinner-grow text-info m-5 " role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
  </p-panel>
</div>