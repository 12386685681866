<div class="container-fluid signinbody">
    <div class="row justify-content-center">
      <div class="  col-sm-10 col-md-7 col-lg-6 col-xl-5 rounded m-2 text-center">
        <img src="assets/img/Fluidra_Blue.png" alt="" class="logo" />
        <div class="box">
            <br>
            <h5 class="fl-color">{{ "lang.change_password_tag" | translate }}</h5>
            <small class="fl-color">{{ "lang.reset_password_new" | translate }}</small>
          <div class="signin-innerbox px-4">
            <br>
            <form [formGroup]="forgotPasswordForm" #form="ngForm">
              <div class="signin-fields">

                <div class="row">
                    <div class="col mb-2 input-group">
                        <!-- <div class="form-group col-sm-12 col-md-12 col-lg-12 col-xs-12 col-md-12"> -->
                            <!-- <label><span>{{'lang.new_pw' | translate}}</span><span class = "text-danger"> *</span></label> -->
                            <span class="input-group-text"><i class="fa-solid fa-lock"></i></span>
                            <input class="form-control" type="password" formControlName="newPassword" autocomplete="none"  
                            [ngClass]="{ 'is-invalid': isFieldValid('newPassword')}" placeholder="{{'lang.new_pw' |translate}}">
                            <div class="float-start invalid-feedback"*ngIf="isFieldValid('newPassword')">
                                <span class="float-centre" *ngIf="forgotPasswordFormControl['newPassword'].errors?.['required']">{{'lang.password' | translate}} {{'lang.validation_required' | translate}} </span>
                                <span *ngIf="forgotPasswordFormControl['newPassword'].errors && !forgotPasswordFormControl['newPassword'].errors?.['required']">{{'lang.tooltip_bad_pw' | translate}}</span>
                            </div>
                        <!-- </div> -->
                    </div>
                </div>

                <div class="row">
                    <div class="col mb-2 input-group">
                        <!-- <div class="form-group col-sm-12 col-md-12 col-lg-12 col-xs-12 col-md-12"> -->
                            <!-- <label><span>{{'lang.confirm_pw' | translate}}</span><span class = "text-danger"> *</span></label> -->
                            <span class="input-group-text"><i class="fa-solid fa-lock"></i></span>
                            <input class="form-control" type="password" formControlName="confirmPassword" autocomplete="none" 
                            [ngClass]="{ 'is-invalid': isFieldValid('confirmPassword')}" placeholder="{{'lang.confirm_pw' |translate}}">
                            <div class="invalid-feedback" *ngIf="isFieldValid('confirmPassword')">
                                <span class="float-centre" *ngIf="forgotPasswordFormControl['confirmPassword'].errors?.['required']">{{'lang.confirm_pw' | translate}} {{'lang.validation_required' | translate}}</span>
                                <span class="float-centre" *ngIf="passwordMatch && !forgotPasswordFormControl['confirmPassword'].errors?.['required']">{{'lang.tooltip_bad_pw_confirm' | translate}}</span>
                            </div> 
                        <!-- </div> -->
                    </div>
                </div>
        
                <div class="signinBottom col mt-2 mb-2 d-grid">
                  <button type="submit" (click)="validate()" class="btn signin-btn btn-signin">{{'lang.password_change_submit' | translate}}</button>
              </div>
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="signin-btm-txt">
            <p>
            Back to
            <a routerLink="/signin">{{ "lang.sign_in" | translate }}</a>
            </p>
        </div> -->
      </div>
    </div>
  </div>