<!-- <p>header works!</p> -->
<!-- <nav
  class="navbar navbar-expand-lg navbar-light bg-light sticky-top toolbar"
  role="banner">
  <a class="navbar-brand"><img src="assets/img/Fluidra_Blue.png" /></a>
  <div class="spacer"></div>
</nav> -->
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/owners-center">
      <img src="assets/img/Fluidra_Blue.png" alt="Logo" >
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item" routerLinkActive="active" *ngIf="!isEngineeringUser">
          <button type="button" class="nav-link" routerLink="/owners-center">{{'lang.location' | translate}}</button>
        </li>

        <li class="nav-item" routerLinkActive="active" *ngIf="isEngineeringUser">
          <button type="button" class="nav-link" routerLink="/engineering">{{'lang.home_tab' | translate}}</button>
        </li>
        

        <li class="nav-item" routerLinkActive="active" *ngIf="isEngineeringUser">
          <button type="button" class="nav-link" routerLink="/register">{{'lang.register' | translate}}</button>
        </li>
        
        
        <li class="nav-item" routerLinkActive="active" *ngIf="!isEngineeringUser">
          <button type="button" class="nav-link" routerLink="/manage-account">{{'lang.heading_manage_acct' | translate}}</button>
        </li>

        <li class="nav-item" routerLinkActive="active" *ngIf="!isEngineeringUser">
          <button type="button" class="nav-link" routerLink="/add-location">{{'lang.add_location' | translate}}</button>
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item">
          <button type="button" class="nav-link" (click)="signOut()">{{'lang.sign_out' | translate}}</button>
        </li>
      </ul>
    </div>
  </div>
</nav>