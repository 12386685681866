<div class="container-fluid signinbody">
    <div class="row justify-content-center">
        <div class="  col-sm-10 col-md-10 col-lg-10 col-xl-10 rounded m-2 ">
            <div class="text-center"><img src="assets/img/Fluidra_Blue.png" alt="" class="logo" /></div>
            <div class="box">
                <h2 class="fl-color text-center">{{ "static.header_privacy_policy" | translate }}</h2>
                <h5 class="fl-color text-center">{{ "static.title" | translate }}</h5>

                <div class="signin-innerbox px-4">
                    <br>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <p class="text-left">{{ "static.Para_1" | translate }}</p>
                            <p class="text-left">{{ "static.Para_2" | translate }}</p>
                            <ul>
                                <li><b>{{ "static.purpose" | translate }}</b> {{ "static.purpose_1" | translate }}</li>
                                <li><b>{{ "static.legal" | translate }}</b> {{ "static.Legal_1" | translate }}</li>
                            </ul>
                            <ul>
                                <li><b>{{ "static.purpose" | translate }}</b> {{ "static.purpose_2" | translate }}</li>
                                <li><b>{{ "static.legal" | translate }}</b> {{ "static.Legal_1" | translate }}</li>
                            </ul>
                            <ul>
                                <li><b>{{ "static.purpose" | translate }}</b> {{ "static.purpose_3" | translate }}</li>
                                <li><b>{{ "static.legal" | translate }}</b> {{ "static.Legal_1" | translate }}</li>
                            </ul>
                            <ul>
                                <li><b>{{ "static.purpose" | translate }}</b> {{ "static.purpose_4" | translate }}</li>
                                <li><b>{{ "static.legal" | translate }}</b> {{ "static.Legal_4" | translate }}</li>
                            </ul>
                            <ul>
                                <li><b>{{ "static.purpose" | translate }}</b> {{ "static.purpose_5" | translate }}</li>
                                <li><b>{{ "static.legal" | translate }}</b> {{ "static.Legal_5" | translate }}</li>
                            </ul>
                            <ul>
                                <li><b>{{ "static.purpose" | translate }}</b> {{ "static.purpose_6" | translate }}</li>
                                <li><b>{{ "static.legal" | translate }}</b> {{ "static.Legal_6" | translate }}</li>
                            </ul>
                            <p class="text-left">{{ "static.point_1" | translate }}</p>
                            <p class="text-left">{{ "static.point_2" | translate }}</p>
                            <p class="text-left">{{ "static.point_3" | translate }}</p>
                            <p class="text-left">{{ "static.point_4" | translate }}</p>
                            <p class="text-left">{{ "static.point_5" | translate }}</p>
                            <p class="text-left">{{ "static.point_6" | translate }}</p>
                            <p class="text-left">{{ "static.point_7" | translate }}</p>
                            <p class="text-left">{{ "static.point_8" | translate }}</p>
                            <p class="text-left">{{ "static.point_9" | translate }}</p>
                            <p class="text-left">{{ "static.point_10" | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
