import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../core/util/loader.service';
import { UtilService } from '../../core/util/util.service';

@Component({
  selector: 'app-privacypolicy',
  templateUrl: './privacypolicy.component.html',
  styleUrls: ['./privacypolicy.component.css']
})
export class PrivacypolicyComponent {
  constructor(private translateService: TranslateService, private utilService: UtilService, private loaderService: LoaderService) {
    this.utilService.checkLanguageConstraintForSignIn();
  }
}
