import { Component, OnInit } from '@angular/core';
import { FormControl, AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilService } from '../../core/util/util.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../core/util/toast.service';
import { emailValidator } from '../../core/util/email-validator.directive';
import { ModalService } from '../../core/util/modal.service';
import { ModalOptions } from 'ngx-bootstrap/modal';
import { LoaderService } from '../../core/util/loader.service';


interface IUser {
  email: string;
}
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {  
  resetForm!: FormGroup;
  user: IUser;

  constructor(private modalService: ModalService, private translateService: TranslateService, private authService: AuthService, private utilService: UtilService, public router: Router, private formBuilder: FormBuilder, private loaderService: LoaderService) {
    this.user = {} as IUser;
    this.utilService.checkLanguageConstraintForSignIn();
    let validToken = this.authService.isAuthenticated();
    if(validToken){
      this.router.navigate([ 'owners-center']);
    }
  }

  ngOnInit(): void {
    this.resetForm = new FormGroup({
      email: new FormControl(this.user.email, [
        Validators.required,
        Validators.maxLength(100),
        emailValidator()
      ])
    });
  }

  get email() {
    return this.resetForm.get('email')!;
  }

  public validate(): void {
    if (this.resetForm.invalid) {
      for (const control of Object.keys(this.resetForm.controls)) {
        this.resetForm.controls[control].markAsTouched();
      }
      return;
    }
    this.user = this.resetForm.value;
    this.onSubmit();
  }

  // * Open model pop up
  openModal(): void { 
    const title = 'Success';
    const data =`<p>You will receive a password reset email shortly sent to the email address used to create your account</p> <p>Please follow the instructions to reset your password.</p>`
    const content = data;
    const isHTML = true;
    const buttonsConfig = [
      {  class:"btn btn-primary",label: 'Return to Login', action: () => this.navigateSignIn() }
    ];
    const onClose = () => {};
    const onShow = () => {};
    const modalConfig: ModalOptions = {
      class: 'modal-dialog-centered', // ? Center modal vertically
      backdrop: 'static', // ? Prevent modal from closing on backdrop click
      keyboard: false, // ? Prevent modal from closing on escape key press
    };
    this.loaderService.hide();
    this.modalService.openModal(title, content,modalConfig, isHTML, buttonsConfig, onClose, onShow);
  }

  navigateSignIn():void{
    this.modalService.closeModal();
    this.router.navigate(['/signin'])
  }

  onSubmit(): void {
    if (this.resetForm.valid) {
      this.authService.resetPassword(this.resetForm.value)?.subscribe(
        (response: any) => {
          if(response?.messages[0]?.text == 'reset_password_msg'){
            this.openModal();
          }
        },
        (error) => {
          console.log(error.error);
        }
      );
    }
  }

}
