export const APP_MODEL_DATA = {
    SUPPORTED_LANG_TRANSLATIONS: ['en', 'es', 'fr', 'it', 'nl', 'pt','de'],
    LANG_VERSION: '1.0',
    expireIntervalBefore : 180,
    getExpiryTime : 10,
    pageSize : 6,
    device_pageSize : 10,
    CURRENT_PAGE: 1,
    TRACK_WEBTOUCH_TIMER: 2000,
    NetworkErrors : [
        'net::ERR_SOCKET_NOT_CONNECTED',
        'net::ERR_FAILED',
        'net::ERR_TIMED_OUT',
        'net::ERR_NETWORK_IO_SUSPENDED',
        'net::ERR_CONNECTION_REFUSED',       // Connection was refused by the server
        'net::ERR_CONNECTION_CLOSED',        // Connection was closed unexpectedly
        'net::ERR_CONNECTION_RESET',         // Connection was reset unexpectedly
        'net::ERR_CONNECTION_ABORTED',       // Connection was aborted unexpectedly
        'net::ERR_CONNECTION_TIMED_OUT',     // Connection attempt timed out
        'net::ERR_INTERNET_DISCONNECTED',    // No internet connection
        'net::ERR_NAME_NOT_RESOLVED',        // DNS resolution failed
        'net::ERR_ADDRESS_UNREACHABLE',      // The address is unreachable
        'net::ERR_NETWORK_CHANGED',          // Network connection was changed
        'net::ERR_SSL_PROTOCOL_ERROR',       // SSL protocol error
        'net::ERR_SSL_VERSION_OR_CIPHER_MISMATCH', // SSL version or cipher mismatch
        'net::ERR_CERT_AUTHORITY_INVALID',   // SSL certificate authority invalid
        'net::ERR_CERT_COMMON_NAME_INVALID', // SSL certificate common name mismatch
        'net::ERR_CERT_DATE_INVALID',        // SSL certificate is expired or not yet valid
        'net::ERR_CERT_WEAK_SIGNATURE_ALGORITHM', // SSL certificate uses weak signature
        'net::ERR_CERTIFICATE_TRANSPARENCY_REQUIRED', // Certificate Transparency is required but missing
        ''
      ],
    PLATFORM_DEVICES_V2_APIKEYANDSECRET: "/platform/devices/v2/apiKeyAndSecret",
    NEW_DEVICES_HPM_HPC_EXO_ZS500: ["hpm", "hpc", "exo", "zs500"],
    NEW_PLATFORM_DEVICES : ["tcx","vr","cyclobat","cyclonext","vortrax"],
    DIGITS_ONLY_REGX: "^[0-9]*$", 
    RPM_DELETE_ACC_KEY : "RPM_DELETE_ACCOUNT_API_KEY",
    COUNTRIES: [{
        "code": "af",
        "display": "Afghanistan"
    }, {
        "code": "ax",
        "display": "Åland Islands"
    }, {
        "code": "al",
        "display": "Albania"
    }, {
        "code": "dz",
        "display": "Algeria"
    }, {
        "code": "as",
        "display": "American Samoa"
    }, {
        "code": "ad",
        "display": "Andorra"
    }, {
        "code": "ao",
        "display": "Angola"
    }, {
        "code": "ai",
        "display": "Anguilla"
    }, {
        "code": "aq",
        "display": "Antarctica"
    }, {
        "code": "ag",
        "display": "Antigua and Barbuda"
    }, {
        "code": "ar",
        "display": "Argentina"
    }, {
        "code": "am",
        "display": "Armenia"
    }, {
        "code": "aw",
        "display": "Aruba"
    }, {
        "code": "au",
        "display": "Australia"
    }, {
        "code": "at",
        "display": "Austria"
    }, {
        "code": "az",
        "display": "Azerbaijan"
    }, {
        "code": "bs",
        "display": "Bahamas"
    }, {
        "code": "bh",
        "display": "Bahrain"
    }, {
        "code": "bd",
        "display": "Bangladesh"
    }, {
        "code": "bb",
        "display": "Barbados"
    }, {
        "code": "by",
        "display": "Belarus"
    }, {
        "code": "be",
        "display": "Belgium"
    }, {
        "code": "bz",
        "display": "Belize"
    }, {
        "code": "bj",
        "display": "Benin"
    }, {
        "code": "bm",
        "display": "Bermuda"
    }, {
        "code": "bt",
        "display": "Bhutan"
    }, {
        "code": "bo",
        "display": "Bolivia"
    }, {
        "code": "ba",
        "display": "Bosnia and Herzegovina"
    }, {
        "code": "bw",
        "display": "Botswana"
    }, {
        "code": "bv",
        "display": "Bouvet Island"
    }, {
        "code": "br",
        "display": "Brazil"
    }, {
        "code": "io",
        "display": "British Indian Ocean Territory"
    }, {
        "code": "bn",
        "display": "Brunei Darussalam"
    }, {
        "code": "bg",
        "display": "Bulgaria"
    }, {
        "code": "bf",
        "display": "Burkina Faso"
    }, {
        "code": "bi",
        "display": "Burundi"
    }, {
        "code": "kh",
        "display": "Cambodia"
    }, {
        "code": "cm",
        "display": "Cameroon"
    }, {
        "code": "ca",
        "display": "Canada"
    }, {
        "code": "cv",
        "display": "Cape Verde"
    }, {
        "code": "ky",
        "display": "Cayman Islands"
    }, {
        "code": "cf",
        "display": "Central African Republic"
    }, {
        "code": "td",
        "display": "Chad"
    }, {
        "code": "cl",
        "display": "Chile"
    }, {
        "code": "cn",
        "display": "China"
    }, {
        "code": "cx",
        "display": "Christmas Island"
    }, {
        "code": "cc",
        "display": "Cocos (Keeling) Islands"
    }, {
        "code": "co",
        "display": "Colombia"
    }, {
        "code": "km",
        "display": "Comoros"
    }, {
        "code": "cg",
        "display": "Congo"
    }, {
        "code": "cd",
        "display": "Congo, The Democratic Republic of The"
    }, {
        "code": "ck",
        "display": "Cook Islands"
    }, {
        "code": "cr",
        "display": "Costa Rica"
    }, {
        "code": "ci",
        "display": "Cote D'ivoire"
    }, {
        "code": "hr",
        "display": "Croatia"
    }, {
        "code": "cu",
        "display": "Cuba"
    }, {
        "code": "cy",
        "display": "Cyprus"
    }, {
        "code": "cz",
        "display": "Czech Republic"
    }, {
        "code": "dk",
        "display": "Denmark"
    }, {
        "code": "dj",
        "display": "Djibouti"
    }, {
        "code": "dm",
        "display": "Dominica"
    }, {
        "code": "do",
        "display": "Dominican Republic"
    }, {
        "code": "ec",
        "display": "Ecuador"
    }, {
        "code": "eg",
        "display": "Egypt"
    }, {
        "code": "sv",
        "display": "El Salvador"
    }, {
        "code": "gq",
        "display": "Equatorial Guinea"
    }, {
        "code": "er",
        "display": "Eritrea"
    }, {
        "code": "ee",
        "display": "Estonia"
    }, {
        "code": "et",
        "display": "Ethiopia"
    }, {
        "code": "fk",
        "display": "Falkland Islands (Malvinas)"
    }, {
        "code": "fo",
        "display": "Faroe Islands"
    }, {
        "code": "fj",
        "display": "Fiji"
    }, {
        "code": "fi",
        "display": "Finland"
    }, {
        "code": "fr",
        "display": "France"
    }, {
        "code": "gf",
        "display": "French Guiana"
    }, {
        "code": "pf",
        "display": "French Polynesia"
    }, {
        "code": "tf",
        "display": "French Southern Territories"
    }, {
        "code": "ga",
        "display": "Gabon"
    }, {
        "code": "gm",
        "display": "Gambia"
    }, {
        "code": "ge",
        "display": "Georgia"
    }, {
        "code": "de",
        "display": "Germany"
    }, {
        "code": "gh",
        "display": "Ghana"
    }, {
        "code": "gi",
        "display": "Gibraltar"
    }, {
        "code": "gr",
        "display": "Greece"
    }, {
        "code": "gl",
        "display": "Greenland"
    }, {
        "code": "gd",
        "display": "Grenada"
    }, {
        "code": "gp",
        "display": "Guadeloupe"
    }, {
        "code": "gu",
        "display": "Guam"
    }, {
        "code": "gt",
        "display": "Guatemala"
    }, {
        "code": "gg",
        "display": "Guernsey"
    }, {
        "code": "gn",
        "display": "Guinea"
    }, {
        "code": "gw",
        "display": "Guinea-bissau"
    }, {
        "code": "gy",
        "display": "Guyana"
    }, {
        "code": "ht",
        "display": "Haiti"
    }, {
        "code": "hm",
        "display": "Heard Island and Mcdonald Islands"
    }, {
        "code": "va",
        "display": "Holy See (Vatican City State)"
    }, {
        "code": "hn",
        "display": "Honduras"
    }, {
        "code": "hk",
        "display": "Hong Kong"
    }, {
        "code": "hu",
        "display": "Hungary"
    }, {
        "code": "is",
        "display": "Iceland"
    }, {
        "code": "in",
        "display": "India"
    }, {
        "code": "id",
        "display": "Indonesia"
    }, {
        "code": "ir",
        "display": "Iran, Islamic Republic of"
    }, {
        "code": "iq",
        "display": "Iraq"
    }, {
        "code": "ie",
        "display": "Ireland"
    }, {
        "code": "im",
        "display": "Isle of Man"
    }, {
        "code": "il",
        "display": "Israel"
    }, {
        "code": "it",
        "display": "Italy"
    }, {
        "code": "jm",
        "display": "Jamaica"
    }, {
        "code": "jp",
        "display": "Japan"
    }, {
        "code": "je",
        "display": "Jersey"
    }, {
        "code": "jo",
        "display": "Jordan"
    }, {
        "code": "kz",
        "display": "Kazakhstan"
    }, {
        "code": "ke",
        "display": "Kenya"
    }, {
        "code": "ki",
        "display": "Kiribati"
    }, {
        "code": "kp",
        "display": "Korea, Democratic People's Republic of"
    }, {
        "code": "kr",
        "display": "Korea, Republic of"
    }, {
        "code": "kw",
        "display": "Kuwait"
    }, {
        "code": "kg",
        "display": "Kyrgyzstan"
    }, {
        "code": "la",
        "display": "Lao People's Democratic Republic"
    }, {
        "code": "lv",
        "display": "Latvia"
    }, {
        "code": "lb",
        "display": "Lebanon"
    }, {
        "code": "ls",
        "display": "Lesotho"
    }, {
        "code": "lr",
        "display": "Liberia"
    }, {
        "code": "ly",
        "display": "Libyan Arab Jamahiriya"
    }, {
        "code": "li",
        "display": "Liechtenstein"
    }, {
        "code": "lt",
        "display": "Lithuania"
    }, {
        "code": "lu",
        "display": "Luxembourg"
    }, {
        "code": "mo",
        "display": "Macao"
    }, {
        "code": "mk",
        "display": "Macedonia, The Former Yugoslav Republic of"
    }, {
        "code": "mg",
        "display": "Madagascar"
    }, {
        "code": "mw",
        "display": "Malawi"
    }, {
        "code": "my",
        "display": "Malaysia"
    }, {
        "code": "mv",
        "display": "Maldives"
    }, {
        "code": "ml",
        "display": "Mali"
    }, {
        "code": "mt",
        "display": "Malta"
    }, {
        "code": "mh",
        "display": "Marshall Islands"
    }, {
        "code": "mq",
        "display": "Martinique"
    }, {
        "code": "mr",
        "display": "Mauritania"
    }, {
        "code": "mu",
        "display": "Mauritius"
    }, {
        "code": "yt",
        "display": "Mayotte"
    }, {
        "code": "mx",
        "display": "Mexico"
    }, {
        "code": "fm",
        "display": "Micronesia, Federated States of"
    }, {
        "code": "md",
        "display": "Moldova, Republic of"
    }, {
        "code": "mc",
        "display": "Monaco"
    }, {
        "code": "mn",
        "display": "Mongolia"
    }, {
        "code": "me",
        "display": "Montenegro"
    }, {
        "code": "ms",
        "display": "Montserrat"
    }, {
        "code": "ma",
        "display": "Morocco"
    }, {
        "code": "mz",
        "display": "Mozambique"
    }, {
        "code": "mm",
        "display": "Myanmar"
    }, {
        "code": "na",
        "display": "Namibia"
    }, {
        "code": "nr",
        "display": "Nauru"
    }, {
        "code": "np",
        "display": "Nepal"
    }, {
        "code": "nl",
        "display": "Netherlands"
    }, {
        "code": "an",
        "display": "Netherlands Antilles"
    }, {
        "code": "nc",
        "display": "New Caledonia"
    }, {
        "code": "nz",
        "display": "New Zealand"
    }, {
        "code": "ni",
        "display": "Nicaragua"
    }, {
        "code": "ne",
        "display": "Niger"
    }, {
        "code": "ng",
        "display": "Nigeria"
    }, {
        "code": "nu",
        "display": "Niue"
    }, {
        "code": "nf",
        "display": "Norfolk Island"
    }, {
        "code": "mp",
        "display": "Northern Mariana Islands"
    }, {
        "code": "no",
        "display": "Norway"
    }, {
        "code": "om",
        "display": "Oman"
    }, {
        "code": "pk",
        "display": "Pakistan"
    }, {
        "code": "pw",
        "display": "Palau"
    }, {
        "code": "ps",
        "display": "Palestinian Territory, Occupied"
    }, {
        "code": "pa",
        "display": "Panama"
    }, {
        "code": "pg",
        "display": "Papua New Guinea"
    }, {
        "code": "py",
        "display": "Paraguay"
    }, {
        "code": "pe",
        "display": "Peru"
    }, {
        "code": "ph",
        "display": "Philippines"
    }, {
        "code": "pn",
        "display": "Pitcairn"
    }, {
        "code": "pl",
        "display": "Poland"
    }, {
        "code": "pt",
        "display": "Portugal"
    }, {
        "code": "pr",
        "display": "Puerto Rico"
    }, {
        "code": "qa",
        "display": "Qatar"
    }, {
        "code": "re",
        "display": "Reunion"
    }, {
        "code": "ro",
        "display": "Romania"
    }, {
        "code": "ru",
        "display": "Russian Federation"
    }, {
        "code": "rw",
        "display": "Rwanda"
    }, {
        "code": "sh",
        "display": "Saint Helena"
    }, {
        "code": "kn",
        "display": "Saint Kitts and Nevis"
    }, {
        "code": "lc",
        "display": "Saint Lucia"
    }, {
        "code": "pm",
        "display": "Saint Pierre and Miquelon"
    }, {
        "code": "vc",
        "display": "Saint Vincent and The Grenadines"
    }, {
        "code": "ws",
        "display": "Samoa"
    }, {
        "code": "sm",
        "display": "San Marino"
    }, {
        "code": "st",
        "display": "Sao Tome and Principe"
    }, {
        "code": "sa",
        "display": "Saudi Arabia"
    }, {
        "code": "sn",
        "display": "Senegal"
    }, {
        "code": "rs",
        "display": "Serbia"
    }, {
        "code": "sc",
        "display": "Seychelles"
    }, {
        "code": "sl",
        "display": "Sierra Leone"
    }, {
        "code": "sg",
        "display": "Singapore"
    }, {
        "code": "sk",
        "display": "Slovakia"
    }, {
        "code": "si",
        "display": "Slovenia"
    }, {
        "code": "sb",
        "display": "Solomon Islands"
    }, {
        "code": "so",
        "display": "Somalia"
    }, {
        "code": "za",
        "display": "South Africa"
    }, {
        "code": "gs",
        "display": "South Georgia and The South Sandwich Islands"
    }, {
        "code": "es",
        "display": "Spain"
    }, {
        "code": "lk",
        "display": "Sri Lanka"
    }, {
        "code": "sd",
        "display": "Sudan"
    }, {
        "code": "sr",
        "display": "Suriname"
    }, {
        "code": "sj",
        "display": "Svalbard and Jan Mayen"
    }, {
        "code": "sz",
        "display": "Swaziland"
    }, {
        "code": "se",
        "display": "Sweden"
    }, {
        "code": "ch",
        "display": "Switzerland"
    }, {
        "code": "sy",
        "display": "Syrian Arab Republic"
    }, {
        "code": "tw",
        "display": "Taiwan, Province of China"
    }, {
        "code": "tj",
        "display": "Tajikistan"
    }, {
        "code": "tz",
        "display": "Tanzania, United Republic of"
    }, {
        "code": "th",
        "display": "Thailand"
    }, {
        "code": "tl",
        "display": "Timor-leste"
    }, {
        "code": "tg",
        "display": "Togo"
    }, {
        "code": "tk",
        "display": "Tokelau"
    }, {
        "code": "to",
        "display": "Tonga"
    }, {
        "code": "tt",
        "display": "Trinidad and Tobago"
    }, {
        "code": "tn",
        "display": "Tunisia"
    }, {
        "code": "tr",
        "display": "Turkey"
    }, {
        "code": "tm",
        "display": "Turkmenistan"
    }, {
        "code": "tc",
        "display": "Turks and Caicos Islands"
    }, {
        "code": "tv",
        "display": "Tuvalu"
    }, {
        "code": "ug",
        "display": "Uganda"
    }, {
        "code": "ua",
        "display": "Ukraine"
    }, {
        "code": "ae",
        "display": "United Arab Emirates"
    }, {
        "code": "gb",
        "display": "United Kingdom"
    }, {
        "code": "us",
        "display": "United States"
    }, {
        "code": "um",
        "display": "United States Minor Outlying Islands"
    }, {
        "code": "uy",
        "display": "Uruguay"
    }, {
        "code": "uz",
        "display": "Uzbekistan"
    }, {
        "code": "vu",
        "display": "Vanuatu"
    }, {
        "code": "ve",
        "display": "Venezuela"
    }, {
        "code": "vn",
        "display": "Viet Nam"
    }, {
        "code": "vg",
        "display": "Virgin Islands, British"
    }, {
        "code": "vi",
        "display": "Virgin Islands, U.S."
    }, {
        "code": "wf",
        "display": "Wallis and Futuna"
    }, {
        "code": "eh",
        "display": "Western Sahara"
    }, {
        "code": "ye",
        "display": "Yemen"
    }, {
        "code": "zm",
        "display": "Zambia"
    }, {
        "code": "zw",
        "display": "Zimbabwe"
    }],
    DEVICE_TYPES: [
    {
        "Id": "i2d",
        "Name": "VS Pump"
    },
    // EQT-22346 New Device Type Vortrax added
    {
        "Id": "vortrax",
        "Name": "Vortrax"
    },
    {
        "Id": "iaqua",
        "Name": "Automation"
    },
    {
        "Id": "i2d_robot",
        "Name": "i2D Robot"
    },
    {
        "Id": "tcx",
        "Name": "TCX"
    },
    {
        "Id": "vr",
        "Name": "Vortex Refresh"
    },
    {
        "Id": "cyclonext",
        "Name": "Cyclonext"
    },
    {
        "Id": "cyclobat",
        "Name": "Cyclobat"
    },
    {
        "Id": "infinity",
        "Name": "Infinity"
    },
    {
        "Id": "npd1",
        "Name": "NPD1"
    },
    {
        "Id": "npd2",
        "Name": "NPD2"
    },
    {
        "Id": "npd3",
        "Name": "NPD3"
    },
    {
        "Id": "npd4",
        "Name": "NPD4"
    },
    {
        "Id": "hpm",
        "Name": "HPM"
    },
    {
        "Id": "hpc",
        "Name": "HPC"
    },
    {
        "Id": "exo",
        "Name": "EXO"
    },
    {
        "Id": "zs500",
        "Name": "ZS500"
    }],
}

export enum ROLE {
    USER = 'user',
    POOLPRO_USER = 'poolpro_user',
    DEV = 'dev',
    PROG = 'prog',
    SUPPORT = 'support',
    SUPPORT_USER = 'support_user',
}

export enum ApiMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH"
  }
