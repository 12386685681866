<!-- <div class="container-fluid router-outlet-container">
    <p>sign-up works!</p>
</div> -->

<div class="container-fluid signinbody">
    <div class="row justify-content-center">
        <div class="  col-sm-10 col-md-10 col-lg-10 col-xl-10 rounded m-2 text-center">
            <img src="assets/img/Fluidra_Blue.png" alt="" class="logo" />
            <div class="box">
                <h2 class="fl-color">{{ "lang.create_account_tag" | translate }}</h2>
                <div class="signin-innerbox px-4">
                    <br>
                    <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <form [formGroup]="registerForm" (ngSubmit)="registerUserInfo()">
                                <div class="row mb-3">
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6  mb-6">
                                        <label><span>{{'lang.email_address' | translate}}</span><span class = "text-danger"> *</span></label>
                                        <input class="form-control" formControlName="enteredEmail" type="text"
                                        [ngClass]="{ 'is-invalid': isFieldValid('enteredEmail')}">
                                        <div class="invalid-feedback"  *ngIf="isFieldValid('enteredEmail')">
                                            <span class="float-start" *ngIf="registerFormControl['enteredEmail'].errors?.['required']">{{'lang.email_address' | translate}} {{'lang.validation_required' | translate}}</span>
                                            <span class="float-start" *ngIf="registerFormControl['enteredEmail'].errors?.['email'] || registerFormControl['enteredEmail'].errors?.['pattern']"> {{'lang.email_valid_error' | translate}}</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                                        <label><span>{{'lang.email_address_confirm' | translate}}</span><span class = "text-danger"> *</span></label>
                                        <input class="form-control" formControlName="confirmedEmail" type="text"
                                        [ngClass]="{ 'is-invalid': isFieldValid('confirmedEmail')}">
                                        <div class="invalid-feedback"  *ngIf="isFieldValid('confirmedEmail')">
                                            <span class="float-start" *ngIf="registerFormControl['confirmedEmail'].errors?.['required']">{{'lang.email_address_confirm' | translate}} {{'lang.validation_required' | translate}}</span>
                                            <span class="float-start" *ngIf="registerFormControl['confirmedEmail'].errors?.['email']|| registerFormControl['confirmedEmail'].errors?.['pattern']"> {{'lang.email_valid_error' | translate}}</span>
                                            <span  class="float-start" *ngIf="emailMatch && !registerFormControl['confirmedEmail'].errors?.['required'] && !registerFormControl['confirmedEmail'].errors?.['pattern'] && !registerFormControl['confirmedEmail'].errors?.['email']">{{'lang.tooltip_bad_email_confirm' |translate}}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 col-md-6">
                                        <label><span>{{'lang.new_pw' | translate}}</span><span class = "text-danger"> *</span></label>
                                        <input class="form-control" type="password" formControlName="enteredPassword" autocomplete="none"  
                                        [ngClass]="{ 'is-invalid': isFieldValid('enteredPassword')}">
                                        <div class="float-start invalid-feedback"*ngIf="isFieldValid('enteredPassword')">
                                            <span class="float-start" *ngIf="registerFormControl['enteredPassword'].errors?.['required']">{{'lang.password' | translate}} {{'lang.validation_required' | translate}} </span>
                                            <span *ngIf="registerFormControl['enteredPassword'].errors && !registerFormControl['enteredPassword'].errors?.['required']">{{'lang.tooltip_bad_pw' | translate}}</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 col-md-6">
                                        <label><span>{{'lang.confirm_pw' | translate}}</span><span class = "text-danger"> *</span></label>
                                        <input class="form-control" type="password" formControlName="confirmedPassword" autocomplete="none" 
                                        [ngClass]="{ 'is-invalid': isFieldValid('confirmedPassword')}">
                                        <div class="invalid-feedback" *ngIf="isFieldValid('confirmedPassword')">
                                            <span class="float-start" *ngIf="registerFormControl['confirmedPassword'].errors?.['required']">{{'lang.confirm_pw' | translate}} {{'lang.validation_required' | translate}}</span>
                                            <span class="float-start" *ngIf="passwordMatch && !registerFormControl['confirmedPassword'].errors?.['required']">{{'lang.tooltip_bad_pw_confirm' | translate}}</span>
                                        </div> 
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-3">
                                        <label><span>{{'lang.first_name' | translate}}</span><span class = "text-danger"> *</span></label>
                                        <input class="form-control" formControlName="enteredFirstName" autocomplete="none"
                                        [ngClass]="{ 'is-invalid': isFieldValid('enteredFirstName')}">
                                        <div class="invalid-feedback"  *ngIf="isFieldValid('enteredFirstName')">
                                            <span class="float-start" *ngIf="registerFormControl['enteredFirstName'].errors?.['required']">{{'lang.first_name' | translate}} {{'lang.validation_required' | translate}}</span>
                                            <span class="float-start" *ngIf="(registerFormControl['enteredFirstName'].errors?.['maxlength'] || registerFormControl['enteredFirstName'].errors?.['minlength']) && !registerFormControl['enteredFirstName'].errors?.['pattern']">{{'lang.invalid_data' | translate}}</span>
                                            <span class="float-start" *ngIf="registerFormControl['enteredFirstName'].errors?.['pattern'] ">{{'lang.validation_whitespace' | translate}}</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                                        <label><span>{{'lang.last_name' | translate}}</span><span class = "text-danger"> *</span></label>
                                        <input class="form-control" formControlName="enteredLastName" autocomplete="none"
                                        [ngClass]="{ 'is-invalid': isFieldValid('enteredLastName')}">
                                        <div class="invalid-feedback" *ngIf="isFieldValid('enteredLastName')">
                                            <span class="float-start" *ngIf="registerFormControl['enteredLastName'].errors?.['required']">{{'lang.last_name' | translate}} {{'lang.validation_required' | translate}}</span>
                                            <span class="float-start" *ngIf="( registerFormControl['enteredLastName'].errors?.['minlength'] || registerFormControl['enteredLastName'].errors?.['maxlength']) && !registerFormControl['enteredLastName'].errors?.['pattern']">{{'lang.invalid_data' | translate}}</span>
                                            <span class="float-start" *ngIf="registerFormControl['enteredLastName'].errors?.['pattern'] ">{{'lang.validation_whitespace' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                                        <label><span>{{'lang.address1' | translate}}</span><span class = "text-danger"> *</span></label>
                                        <textarea class="form-control" formControlName="enteredAddress1" autocomplete="none"
                                        [ngClass]="{ 'is-invalid': isFieldValid('enteredAddress1')}"></textarea>
                                        <div class="invalid-feedback" *ngIf="isFieldValid('enteredAddress1')">
                                            <span class="float-start" *ngIf="registerFormControl['enteredAddress1'].errors?.['required']">{{'lang.address1' | translate}} {{'lang.validation_required' | translate}}</span>
                                            <span class="float-start" *ngIf="registerFormControl['enteredAddress1'].errors?.['pattern']">{{'lang.validation_whitespace' | translate}}</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                                        <label>{{'lang.address2' | translate}}</label>
                                        <textarea class="form-control" formControlName="enteredAddress2" autocomplete="none"></textarea>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                                        <label><span>{{'lang.city' | translate}}</span><span class = "text-danger"> *</span></label>
                                        <input class="form-control" formControlName="enteredCity" autocomplete="none"  
                                        [ngClass]="{ 'is-invalid': isFieldValid('enteredCity')}">
                                        <div class="invalid-feedback" *ngIf="isFieldValid('enteredCity')">
                                            <span class="float-start" *ngIf="registerFormControl['enteredCity'].errors?.['required']">{{'lang.city' | translate}} {{'lang.validation_required' | translate}}</span>
                                            <span class="float-start" *ngIf="registerFormControl['enteredCity'].errors?.['pattern']">{{'lang.validation_whitespace' | translate}}</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                                        <label><span>{{'lang.state' | translate}}</span><span class = "text-danger"> *</span></label>
                                        <input class="form-control" formControlName="enteredState" autocomplete="none" 
                                        [ngClass]="{ 'is-invalid': isFieldValid('enteredState')}">
                                        <div class="invalid-feedback" *ngIf="isFieldValid('enteredState')">
                                            <span class="float-start" *ngIf="registerFormControl['enteredState'].errors?.['required']">{{'lang.state' | translate}} {{'lang.validation_required' | translate}}</span>
                                            <span class="float-start" *ngIf="registerFormControl['enteredState'].errors?.['pattern']">{{'lang.validation_whitespace' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                                        <label><span>{{'lang.postal_code' | translate}}</span><span class = "text-danger"> *</span></label>
                                        <input class="form-control" formControlName="enteredPostalCode" autocomplete="none"
                                        [ngClass]="{ 'is-invalid': isFieldValid('enteredPostalCode')}">
                                        <div class="invalid-feedback" *ngIf="isFieldValid('enteredPostalCode')">
                                            <span class="float-start" *ngIf="registerFormControl['enteredPostalCode'].errors?.['required']">{{'lang.postal_code' | translate}} {{'lang.validation_required' | translate}}</span>
                                            <span class="float-start" *ngIf="registerFormControl['enteredPostalCode'].errors?.['pattern']">{{'lang.validation_numberOnly' | translate}}</span>
                                        </div>
                                    </div> 
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                                      <label><span>{{'lang.country' | translate}}</span><span class = "text-danger"> *</span></label>
                                      <select class="form-control" formControlName="enteredCountryCode"
                                      [ngClass]="{ 'is-invalid': isFieldValid('enteredCountryCode')}">
                                          <option *ngFor="let country of countries" [ngValue]="country.code">{{country.display}}</option>
                                      </select>
                                      <div class="invalid-feedback" *ngIf="isFieldValid('enteredCountryCode')">
                                          <span class="float-start" *ngIf="registerFormControl['enteredCountryCode'].errors?.['required']">{{'lang.country' | translate}} {{'lang.validation_required' | translate}}</span>
                                      </div>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                                        <label><span>{{'lang.telephone' | translate}}</span><span class = "text-danger"> *</span></label>
                                        <input class="form-control" formControlName="enteredTelephone" autocomplete="none"
                                        [ngClass]="{ 'is-invalid': isFieldValid('enteredTelephone')}">
                                        <div class="invalid-feedback" *ngIf="isFieldValid('enteredTelephone')">
                                            <span class="float-start" *ngIf="registerFormControl['enteredTelephone'].errors?.['required']">{{'lang.telephone' | translate}} {{'lang.validation_required' | translate}}</span>
                                            <span class="float-start" *ngIf="registerFormControl['enteredTelephone'].errors?.['pattern']">{{'lang.validation_numberOnly' | translate}}</span>
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6"></div>
                                 </div>
                                 <div class="row ms-1">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="1" formControlName="enteredTerms" id="enteredTerms"
                                        [ngClass]="{ 'is-invalid': isFieldValid('enteredTerms')}"/>
                                        <label class="form-check-label" for="flexCheckDefault">
                                          <a href="https://www.zodiac-poolcare.com/legal-information" target="_blank">  {{'lang.terms_and_conditions' | translate}}</a>
                                        </label>
                                      </div>
                                 </div>
                                 <div class="row ms-1">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="1" formControlName="opt_1" id="opt_1">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            {{'lang.opt_1' | translate}}
                                        </label>
                                      </div>
                                 </div>
                                 <div class="row ms-1">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" value="1" formControlName="opt_2" id="opt_1">
                                        <label class="form-check-label" for="flexCheckDefault">
                                            {{'lang.opt_2' | translate}}
                                        </label>
                                      </div>
                                 </div>
                                 <br>
                                 <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                                    <button class="btn btn-light btn-sm me-md-2" type="button" routerLink="/signin">{{'lang.cancel' | translate}}</button>
                                    <button class="btn btn-warning btn-sm" type="submit">{{'lang.sign_up' | translate}}</button>
                                  </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>