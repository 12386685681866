export const environment = {
    ENABLE_PROD_MODE: false,
    apiUrl: "https://prm.zodiac-dev.com/v2",
    rubyApiUrl: "https://prm.zodiac-dev.com",
    AmzXApiKey:'CJCINQCRIVX4E7YTHIXASXVVLMQAB4Z6',
    webTouch:"https://webtouch.zodiac-dev.com",
    domain:'.zodiac-dev.com',
    loginApi:"https://dev.zodiac-io.com/users/v1/login",
    // refreshApi:"https://dev.zodiac-io.com/users/v1/refresh",
    refreshApi: "https://dev.zodiac-io.com/users/v1/refresh4InviteCode",
    // loginApi:"https://k4qfpzesa6.execute-api.us-west-2.amazonaws.com/dev/v1/login-v3",
    tokenUrl:"https://zodiac-dev.auth.us-west-2.amazoncognito.com/oauth2/token",
    legacyUrl:"https://www.zodiac-dev.com",
    clientId:"4d97s3h65r2799ubcc8ib1gk62",
    platformIoUrl:"https://dev.zodiac-io.com/devices/v2",
    retryAttempts:2,
    platformIoUrlV1:"https://dev.zodiac-io.com/devices/v1",
    passwordMinLength:8,
    passwordMaxLength:32,
    supportUrl:"https://support.zodiac-dev.com",
    userAuthPwdFormDomain: "https://dev.zodiac-io.com",
    chatBotcss:[
        'https://chatbot.zodiac-dev.com/FAQChatbot-UI/Ver1.0/css/botwindow.css',
        'https://chatbot.zodiac-dev.com/FAQChatbot-UI/Ver1.0/css/swiper.min.css'
    ],
    chatBotjs:[
        'https://sdk.amazonaws.com/js/aws-sdk-2.923.0.min.js',
        'https://unpkg.com/axios/dist/axios.min.js',
        'https://chatbot.zodiac-dev.com/FAQChatbot-UI/Ver1.0/js/swiper.min.js',
        'https://chatbot.zodiac-dev.com/FAQChatbot-UI/Ver1.0/js/botconfig.js',
        'https://chatbot.zodiac-dev.com/FAQChatbot-UI/Ver1.0/js/botrender.js',
        'https://chatbot.zodiac-dev.com/FAQChatbot-UI/Ver1.0/js/botwindow.js'
      ]
}    
