<div class="container mt-5">
    <p-panel header="{{'lang.add_location' |translate}}">
      <h5 class="bold fl-color  p-2"> {{'lang.add_step_text'| translate}}</h5>
      <form [formGroup]="form"  *ngIf="!isStepOneComplete" (ngSubmit)="submitSerialNo()">
        <div class="form-group row g-3 col-md-5 col-sm-5 col-xs-12 col-lg-5  p-2">
              <label for="input" class="form-label small mb-1">{{'lang.enter_serial'| translate}}</label>
              <div class="input-group mb-3 mt-1 ">
                <input type="text" class="form-control" formControlName="serialNo" id="serialNo"
                     placeholder="xxx-xxx-xxx-xxx" maxlength="15" minlength="15" (input)="onInput($event)">
                     <button class="input-group-text" type="submit" [ngClass]="form.valid ? 'text-success iaqua':'text-warning cursorPointer'" [disabled]="!form.valid"> {{'lang.next'| translate}}</button>
              </div>
              <div *ngIf="serialNo?.invalid && (serialNo?.dirty || serialNo?.touched)" class="text-danger mt-0 small">
                  <div *ngIf="serialNo?.errors?.['required']">
                    {{'lang.alert_serial'| translate}}
                  </div>
                  <div *ngIf="serialNo?.errors?.['maxlength'] || serialNo?.errors?.['minlength']">
                    {{'lang.alert_serial_size'| translate}}
                  </div>
              </div>
        </div>
      </form>
      <form [formGroup]="form2" *ngIf="isStepOneComplete" (ngSubmit)="submitLocation()">
        <div class="form-group row g-3 col-md-5 col-sm-5 col-xs-12 col-lg-5  p-2">
              <div class="input-group mb-3">
                <input type="text" class="form-control" formControlName="name" id="name"
                placeholder="{{'lang.name' |translate}}" maxlength="18">
                     <button class="input-group-text" type="submit" [ngClass]="form2.valid ? 'text-success iaqua':'text-warning cursorPointer'" [disabled]="!form2.valid"> {{'lang.finish'| translate}}</button>
              </div>
              <div *ngIf="name?.invalid && (name?.dirty || name?.touched)" class="text-danger mt-0 small">
                  <div *ngIf="name?.errors?.['required']">
                    {{'lang.name_required'| translate}}
                  </div>
                  <div *ngIf="name?.errors?.['maxlength']">
                    {{'lang.char_counter'| translate}}
                  </div>
              </div>
        </div>
      </form>
    </p-panel>
  </div>
