import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectionService {

  constructor(private router: Router) { }

  handleRedirection(url: string) {
      // Match and redirect /sign-up.html
       const signUpPattern = /\/sign-up\.html\?lang=(.*)/;
        const signUpMatch = url.match(signUpPattern);
        if (signUpMatch) {
          const lang = signUpMatch[1];
          const newUrl = `/sign-up?lang=${lang}`;
          this.router.navigateByUrl(newUrl);
          return;
        }

        // Match and redirect /signin.html
        const signInPattern = /\/signin\.html\?lang=(.*)/;
        const signInMatch = url.match(signInPattern);
        if (signInMatch) {
          const lang = signInMatch[1];
          const newUrl = `/signin?lang=${lang}`;
          this.router.navigateByUrl(newUrl);
          return;
        }
        
        // Match and redirect/start/iAqua3?lang=en&amp;sub=us
        const startPattern = /\/start\/iAqua3\/?\?lang=([^&]*)(&sub=([^&]*))?/;
        const startMatch = url.match(startPattern);
        
        if (startMatch) {
          const lang = startMatch[1]; // captures lang value
          const sub = startMatch[3] || ''; // captures sub value, or defaults to empty if sub is not present
        
          // Construct the new URL for redirection
          const newUrl = `/signin?lang=${lang}`;
        
          // Navigate to the new URL
          this.router.navigateByUrl(newUrl);
          return;
        }

      // Match and redirect /static/privacypolicy/en.html
      const privacyPolicyPattern = /\/static\/privacypolicy\/(\w+)\.html/;
      const privacyPolicyMatch = url.match(privacyPolicyPattern);
      if (privacyPolicyMatch) {
        const lang = privacyPolicyMatch[1];
        const newUrl = `/static/privacypolicy?lang=${lang}`;
        this.router.navigateByUrl(newUrl);
        return;
      }
  
      // Match and redirect /changePassword.html
      const changePasswordPattern = /\/changePassword\.html\?reset_password_token=(.*)/;
      const changePasswordMatch = url.match(changePasswordPattern);
      if (changePasswordMatch) {
        const token = changePasswordMatch[1];
        const newUrl = `/changePassword?reset_password_token=${token}`;
        this.router.navigateByUrl(newUrl);
       // window.location.href = newUrl;
        return;
      }
  
      // Match and redirect /deleteAccount.html
      const deleteAccountPattern = /\/deleteAccount\.html\?delete_password_token=(.*)&email=(.*)&lang=(.*)/;
      const deleteAccountMatch = url.match(deleteAccountPattern);
      if (deleteAccountMatch) {
        const token = deleteAccountMatch[1];
        const email = deleteAccountMatch[2];
        const lang = deleteAccountMatch[3];
        const newUrl = `/deleteAccount?delete_password_token=${token}&email=${email}&lang=${lang}`;
        this.router.navigateByUrl(newUrl);
        // window.location.href = newUrl;
        return;
      }
  }
}
