<div class="container-fluid signinbody">
    <div class="row justify-content-center">
      <div class="  col-sm-10 col-md-7 col-lg-6 col-xl-5 rounded m-2 text-center">
        <img src="assets/img/Fluidra_Blue.png" alt="" class="logo" />
        <div class="box">
            <br>
            <h5 class="fl-color">{{ "lang.delete_account" | translate }}</h5>
            <small class="fl-color text-left">{{ "lang.hello" | translate }} {{email}}, </small><br>
            <small class="fl-color">{{ "lang.delete_account_para_1" | translate }}</small>
            <small class="fl-color">{{ "lang.delete_account_para_2" | translate }}</small>
          <div class="signin-innerbox px-4">
            <br>
            <form [formGroup]="passwordForm" #form="ngForm">
              <div class="signin-fields">
                <div class="row">
                    <div class="col mb-2">
                      <div class="input-group has-validation">
                        <span class="input-group-text"><i class="fa-solid fa-lock"></i></span>
                        <input [type]="deleteUser.showPassword ? 'text' : 'password'" id="password" name="password"
                          placeholder="{{'lang.password'| translate}}" formControlName="password" required
                          class="form-control" [class.is-invalid]="
                              password.invalid && (password.dirty || password.touched)" />
                          <span class="input-group-text" *ngIf="password.value" (click)="deleteUser.showPassword = !deleteUser.showPassword">
                              <i [ngClass]="{'fa-regular fa-eye-slash': !deleteUser.showPassword,'fa-sharp fa-solid fa-eye': deleteUser.showPassword}"></i>
                        </span>
    
                        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
                          <div *ngIf="password.errors?.['required']">
                            {{'lang.password'| translate}} is required.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
        
                <div class="signinBottom col mt-2 mb-2 d-grid">
                  <button type="submit" (click)="validate()" class="btn btn-signin delete-btn">{{'lang.delete' | translate}}</button>
              </div>
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="signin-btm-txt">
            <p>
            Back to
            <a routerLink="/signin">{{ "lang.sign_in" | translate }}</a>
            </p>
        </div> -->
      </div>
    </div>
  </div>