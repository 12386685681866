import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    const userInfo = JSON.parse(this.authService.getLocalStoarge('userInfo') || '{}');
    const role = userInfo?.userLevel;


    if (role === 'dev') {
      this.router.navigate(['/engineering']);
    } else if ((role === 'user'|| role === 'poolpro_user') && !userInfo.email.includes("@fluidra")) {
      this.router.navigate(['/owners-center']);
    } else if (userInfo?.email?.includes("@fluidra") && userInfo?.userLevel == 'user') { // user
      this.router.navigate(['/owners-center']);
    }else {
      this.router.navigate(['/signin']);
    }
  }
}
