<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->

<!-- Toolbar -->

<app-header *ngIf="isLogin"></app-header>

<!-- <div class="content" role="main"> -->
 
  <!-- <div class="card-container"> -->
    <app-loader></app-loader>
    <router-outlet></router-outlet>
  <!-- </div> -->

  <!-- Footer -->
 <!-- <app-footer></app-footer> -->

<!-- </div> -->

