import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], searchText: string):  { filteredItems: any[], count: number, notFound: boolean }  {
    if (!items || !searchText) {
      return { filteredItems: items, count: items.length, notFound: false };

    }
    
    searchText = searchText.toLowerCase();
  
    const filteredItems = items.filter(item => {
      return Object.keys(item).some(key => 
        item[key].toString().toLowerCase().includes(searchText)
      );
    });

    return { filteredItems: filteredItems, count: filteredItems.length, notFound: filteredItems.length === 0 };
  }

}
