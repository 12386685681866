import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { APP_MODEL_DATA } from '../../core/const-data';
import { AuthService } from 'src/app/auth/auth.service';
import { LoaderService } from '../../core/util/loader.service';
import { UserService } from '../../dashboard/user.service';
import { ToastService } from '../../core/util/toast.service';
import { ModalService } from '../../core/util/modal.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalOptions } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css']
})
export class DeviceListComponent {
  isLogin = false;
  sortDetails = {
    "offset":0,
    "sortKey":"created_at",
    "sort":"desc"
  };
  rows: any;
  returnRow: any;
  pageSize = APP_MODEL_DATA.device_pageSize;
  currentPage = APP_MODEL_DATA.CURRENT_PAGE;
  loadTable = false;
  count: number = 0;
  page: number = 1;
  currentSortColumn: string = '';
  currentSortDirection: 'asc' | 'desc' = 'asc';

  constructor(private router: Router,private formBuilder: FormBuilder,private modalService: ModalService, private translateService: TranslateService,private toastService: ToastService, private userService: UserService,private loaderService: LoaderService, private authService: AuthService){
    this.isLogin = this.authService.isAuthenticated();
    if(this.isLogin && this.authService.checkEngineerUser()){
      this.getDeviceList();
    } else if(this.isLogin && !this.authService.checkEngineerUser()){
      this.router.navigate(['owners-center']);
    }
     else {
      this.authService.clearStorage();
    }
  }

  getDeviceList(){
    this.userService.getDeviceList(this.sortDetails)?.subscribe((device:any)=>{
      this.tableRowInit(device);
      // console.log(device)
    });
  }

  tableRowInit(device:any){
    this.rows = device?.response;
    // this.rows = this.deviceData.locations;
    this.rows.map((i: { [x: string]: boolean; })=>{i['isExpand']=false,i['isEdit']=false});
    this.returnRow = this.rows.slice(0,this.pageSize);
    this.count = device?.count; 
    this.loadTable =true
    this.loaderService.hide();
  }

  sortBy(prop: string) {
    // console.log(prop);
    if (this.currentSortColumn === prop) {
      this.currentSortDirection = this.currentSortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.currentSortColumn = prop;
      this.currentSortDirection = 'asc';
    }
    this.sortDetails = {
      "offset":0,
      "sortKey":this.currentSortColumn,
      "sort":this.currentSortDirection
    }
    this.getDeviceList();
    // this.rows.sort((a: { [x: string]: number; }, b: { [x: string]: number; }) => {
    //   let compare = 0;
    //   if (a[prop] < b[prop]) compare = -1;
    //   if (a[prop] > b[prop]) compare = 1;
    //   return this.currentSortDirection === 'asc' ? compare : compare * -1;
    // });
  }
  pageChanged(page:any): void {
    this.page = page;
    const startItem = (this.page - 1) * this.pageSize;
    const endItem = this.page * this.pageSize;
    console.log(endItem);
    this.returnRow = this.rows.slice(startItem, endItem);
  }

    // * Open model pop up
    openModal(device:any): void {  // delete row modal pop up
      let oldDevice = APP_MODEL_DATA.NEW_DEVICES_HPM_HPC_EXO_ZS500.includes(device.device_type);
      const title = 'Delete: ' + device?.module_label;
      let content = ''
      let data = ''
      let buttonsConfig = []; 
      if(oldDevice){
         data = this.translateService.instant('lang.remove_device_alert')
         buttonsConfig = [
          { class:"btn btn-danger",label: this.translateService.instant('lang.ok'), action: () => this.modalService.closeModal() }
        ];

      } else {
         data = this.translateService.instant('lang.confirm_remove_device')
         buttonsConfig = [
           { class:"btn btn-danger",label: this.translateService.instant('lang.ok'), action: () => this.removeDevice(device) }
         ];
      }
      content = data;
      const isHTML = false;
      const onClose = () => {};
      const onShow = () => {};
      const modalConfig: ModalOptions = {
        class: 'modal-dialog-centered', // ? Center modal vertically
        backdrop: 'static', // ? Prevent modal from closing on backdrop click
        keyboard: false, // ? Prevent modal from closing on escape key press
      };
      this.modalService.openModal(title, content,modalConfig, isHTML, buttonsConfig, onClose, onShow);
    }

  removeDevice(device: any){
    let isNewPlatformDevice = APP_MODEL_DATA.NEW_PLATFORM_DEVICES.includes(device.device_type);
    if(isNewPlatformDevice){
      this.userService.removeNewPlatformDevice(device?.serial_number)?.subscribe((data:any)=>{
        this.loaderService.hide();
        this.modalService.closeModal();
        // this.tableRowInit(device);
        this.getDeviceList();
        this.toastService.showSuccess('', this.translateService.instant('lang.modal_saving_success'));
        // console.log(device)
      },
      (error) => {
        console.log(error.error);
        this.loaderService.hide();
        this.modalService.closeModal();
      });
    }
    else{
      this.userService.removeLegacyDevice(device?.serial_number)?.subscribe((data:any)=>{
        // console.log(data.locations);
        this.loaderService.hide();
        this.modalService.closeModal();
        this.getDeviceList();
        this.toastService.showSuccess('', this.translateService.instant('lang.modal_saving_success'));
        // console.log(device)
      },
      (error) => {
        console.log(error.error);
        this.loaderService.hide();
        this.modalService.closeModal();
      });
    }

  }

}
