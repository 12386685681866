<div class="container mt-5">

  <p-panel header="{{'lang.location' |translate}}">
    <!-- <p> -->
      <h5 class="bold fl-color p-2"> {{'lang.heading_welcome'| translate}}</h5>
    <!-- </p> -->
      <form *ngIf="loadTable">
      <div class="row col-sm-5 col-md-5 col-xs-12 p-2" >
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="search" name="search" *ngIf="enableSearch"/>
            <span class="input-group-text" *ngIf="search" (click)="reset()" role="button" tooltip="{{'lang.cancel' | translate}}" >
              <i class='fa fa-times'></i>
            </span>  
          </div>
      </div>
      <div *ngIf="(returnRow | search: search) as result">
        <p class="ms-3 small">Displaying: {{result?.count}} of {{rows?.length}}</p>
        <p-divider></p-divider>
        <div class="table-responsive" *ngIf="!result.notFound">
          <table class="table">
            <tbody>
              <ng-container *ngFor="let device of (returnRow | search: search).filteredItems ; let i = index" >
                <tr >
                  <td role="button" [ngClass]= "device?.device_type == 'iaqua'? 'cursorPointer': 'cursorNotAllowed'" (click)="launchWebTouchClick(device,'')">
                    <ng-container>
                      <i class="icon-iaqualink-iaqua"></i>
                      {{$any(device).Name}}
                    </ng-container>
                  </td>
                    <!-- <td role="button" (click)="device.isExpand = !device.isExpand; deviceStatus(device,i)"> -->
                      <td role="button" (click)="deviceStatus(device,i)">

                      <ng-container *ngIf="!device.isExpand">  <i class="icon-iaqualink-info" ></i>  {{ 'lang.device_status'| translate }} </ng-container> 
                      <ng-container *ngIf="device.isExpand">  <i class='icon-iaqualink-close'></i> {{'lang.close'| translate }}</ng-container> 
                    </td>
                    <!-- <td role="button" (click)="device.isEdit = !device.isEdit"> -->
                      <td role="button" (click)="deviceEdit(device,i)">
                      <ng-container *ngIf="!device.isEdit">  <i class="icon-iaqualink-edit" ></i> {{'lang.edit_name'| translate }} </ng-container> 
                      <ng-container *ngIf="device.isEdit">  <i class='icon-iaqualink-close'></i> {{'lang.close'| translate }}</ng-container> 
                    </td>
                    <td>
                      <i class="icon-iaqualink-trash" role="button" (click)="openModal(device)"></i>
                      {{ 'lang.delete'| translate }}
                    </td>
                </tr>
                <ng-container class="m-1" *ngIf="device.isExpand && loadDetails">
                  <div class="card p-2 " style="border: none;">
                    <div class="card-body border">
                      <p class="card-text text-muted small mt-0" *ngIf="deviceDetails?.Status === 'Offline'">{{'lang.status_msg_off' | translate}}</p>
                      <p class="card-text text-muted fs-6 small mt-0" *ngIf="deviceDetails?.Status === 'online'">{{'lang.status_msg_on' | translate}}</p>
                      <span class="card-text small">{{'lang.status' | translate}} : <span [ngClass]="deviceDetails?.Status === 'online'? 'fst-italic text-success': 'fst-italic text-danger'" class=" mt-0">{{deviceDetails?.Status}}</span></span>
                      <p class="card-text small">{{'lang.serial_number' | translate}} : {{deviceDetails?.Serial}}</p>
                    </div>
                  </div>
                </ng-container >

                <ng-container *ngIf="device.isEdit">
                  <div class="card p-2 " style="border: none;">
                    <div class="card-body border">
                      <p class="card-text text-muted fs-6  p-2 small">{{'lang.edit_name_summary' | translate}}</p>
                      <form [formGroup]="editForm" (ngSubmit)="editNameInfo(device)">
                      <div class="input-group mb-3">
                        <input type="text" class="form-control" formControlName="name" placeholder="{{'lang.edit_name' |translate}}" [ngClass]="{ 'is-invalid': isFieldValid('name')}">
                        <button class="input-group-text" type="submit" [ngClass]="editForm.valid ? 'text-success':'text-danger'" [disabled]="!editForm.valid"><i class='fa fa-check'></i></button>
                      </div>
                      <div *ngIf="name?.invalid && (name?.dirty || name?.touched)" class="text-danger small">
                        <div *ngIf="name?.errors?.['required']">
                          {{'lang.name_required' |translate}}
                        </div>
                        <div *ngIf="name?.errors?.['minLength']">
                          {{'lang.char_counter' | translate}}
                        </div>
                        <div *ngIf="name?.errors?.['maxlength']">
                          {{'lang.char_counter' | translate}}
                        </div>
                      </div>
                    </form>
                    </div>
                  </div>
                </ng-container>

              </ng-container>
            </tbody>
          </table>
        </div>
        <div class="table-responsive" *ngIf="result.notFound || rows.length < 1">
          <table class="table table-striped">
            <tbody>
              <tr>
                <td>No Devices found.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- {{!(search!= '')}}
        {{result?.count}}
        {{!(rows.length < 1)}} -->
         <!-- {{result.count > 5}} {{count}} {{rows?.length}} {{currentPage}} {{pageSize}} {{result.count > 5 && rows?.length > pageSize}} -->
        <!-- <div class="d-flex justify-content-between" *ngIf="result.count > 5 || !(search!= '') || rows.length > 5">  -->
          <ng-container  *ngIf="enablePagination">
            <div class="d-flex justify-content-between" *ngIf=" result.count > 5 || !(search!= '')"> 
              <pagination [totalItems]="count" class="pagination"
              [itemsPerPage]="pageSize" 
              [boundaryLinks]="true"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
              [(ngModel)]="currentPage"
              name="currentPage"
              (click)="pageChanged(currentPage)"></pagination>
            </div>
          </ng-container>
      </div>
      </form>
      <div class="d-flex justify-content-center" *ngIf="!loadTable">
        <div class="spinner-grow text-info m-5 " role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
  </p-panel>
</div>
