import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { ManageAccountComponent } from './manage-account/manage-account.component';
import { AddLocationComponent } from './add-location/add-location.component';
import { PanelModule } from 'primeng/panel';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'ngx-bootstrap/tooltip';


import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { langTranslateHttpLoader } from '../lang-translate.loader';
import { HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { SearchPipe } from '../core/util/search.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { AccordionModule } from 'ngx-bootstrap/accordion';

@NgModule({
  declarations: [
    DashboardComponent,
    ManageAccountComponent,
    SearchPipe,
    AddLocationComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    PanelModule,
    DividerModule,
    AccordionModule,
    TooltipModule,
    BrowserModule,
    ReactiveFormsModule,
    DashboardRoutingModule,
    PaginationModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: langTranslateHttpLoader,
        deps: [HttpClient]
      }
    }),
  ],
  exports:[
    PaginationModule
],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers:[SearchPipe]
})
export class DashboardModule { }
