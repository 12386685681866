import { DefaultUrlSerializer, UrlTree, UrlSerializer } from '@angular/router';

export class CustomUrlSerializer extends DefaultUrlSerializer {
  override parse(url: string): UrlTree {
    let returnUrl = super.parse(url);
    return returnUrl;
  }

  override serialize(tree: UrlTree): any {
      const defaultSerializedUrl = super.serialize(tree);
      return defaultSerializedUrl;
  }
}
