<div class="container-fluid signinbody">
  <div class="row justify-content-center">
    <div class="  col-sm-10 col-md-7 col-lg-6 col-xl-5 rounded m-2 text-center">
      <img src="assets/img/Fluidra_Blue.png" alt="" class="logo" />
      <div class="box">
        <h2>{{ "lang.welcome1" | translate }}</h2>
        <h2>{{ "lang.welcome2" | translate }}</h2>
        <div class="signin-innerbox px-4">
          <form [formGroup]="loginForm" #form="ngForm">
            <div class="signin-fields">
              <div class="row">
                <div class="col mb-2 input-group">
                  <span class="input-group-text"><i class="fa-regular fa-user"></i></span>
                  <input type="email" id="email" name="email" formControlName="email" placeholder="{{'lang.email_address' |translate}}" required minlength="1" maxlength="250" class="form-control"
                    [class.is-invalid]="email.invalid && (email.dirty || email.touched)" />
                  <div *ngIf="email.invalid && (email.dirty || email.touched)" class="invalid-feedback">
                    <div *ngIf="email.errors?.['required']">
                      {{'lang.email_address' | translate}} is required.
                    </div>
                    <div *ngIf="email.errors?.['minlength']">
                      {{'lang.email_address' | translate}} must have at least 1 character.
                    </div>
                    <div *ngIf="email.errors?.['maxlength']">
                      {{'lang.email_address' | translate}} must have at most 250 characters.
                    </div>
                    <div *ngIf="!email.errors?.['required'] && !email.errors?.['minlength'] && !email.errors?.['maxlength'] && email.errors?.['emailValidator']">
                      Invalid  {{'lang.email_address' | translate}} format.
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col mb-2">
                  <div class="input-group has-validation">
                    <span class="input-group-text"><i class="fa-solid fa-lock"></i></span>
                    <input [type]="user.showPassword ? 'text' : 'password'" id="password" name="password"
                      placeholder="{{'lang.password'| translate}}" formControlName="password" required
                      class="form-control" [class.is-invalid]="
                          password.invalid && (password.dirty || password.touched)" />
                      <span class="input-group-text" *ngIf="password.value" (click)="user.showPassword = !user.showPassword">
                          <i [ngClass]="{'fa-regular fa-eye-slash': !user.showPassword,'fa-sharp fa-solid fa-eye': user.showPassword}"></i>
                    </span>

                    <div *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
                      <div *ngIf="password.errors?.['required']">
                        {{'lang.password'| translate}} is required.
                      </div>
                      <div *ngIf="password.errors?.['pattern']">
                        {{'lang.tooltip_bad_pw'| translate}}
                      </div>
                      <!-- <span *ngIf="registerFormControl['enteredPassword'].errors && !registerFormControl['enteredPassword'].errors?.['required']">{{'lang.tooltip_bad_pw' | translate}}</span> -->

                    </div>
                  </div>
                </div>
              </div>
      
              <div class="signinBottom col mt-2 mb-2 d-grid">
                <button type="submit" (click)="validate()" class="btn signin-btn btn-signin">{{'lang.sign_in' | translate}}</button>
            </div>
            </div>
          </form>
        </div>
        <div class="signin-btm-txt">
          <span>{{ "lang.new_user1" | translate }}</span> 
          <button routerLink="/sign-up" class="btn signup-btn btn-signup" >{{'lang.sign_up' | translate}}</button>
          <span>{{ "lang.new_user2" | translate }}</span>
      </div>
      </div>
      <div class="signin-btm-txt">
        <p>
          {{ "lang.trouble_msg1" | translate }}
          <a routerLink="/resetPassword">{{ "lang.trouble_msg2" | translate }}</a>
        </p>
      </div>
    </div>
  </div>
</div>