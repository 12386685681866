import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, UrlSerializer } from '@angular/router';
import { SignInComponent } from './auth/sign-in/sign-in.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { AuthGuardService } from './auth/auth.guard';
import { ManageAccountComponent } from './dashboard/manage-account/manage-account.component';
import { AddLocationComponent } from './dashboard/add-location/add-location.component';
import {APP_MODEL_DATA} from './core/const-data'
import { ForgotUserPasswordComponent } from './auth/forgot-user-password/forgot-user-password.component';
import { DeleteAccountComponent } from './auth/delete-account/delete-account.component';
import { DeviceListComponent } from './device/device-list/device-list.component';
import { RegisterDeviceComponent } from './device/register-device/register-device.component';
import { RedirectComponent } from './core/redirect/redirect.component';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { PrivacypolicyComponent } from './auth/privacypolicy/privacypolicy.component';
import { CustomUrlSerializer } from './core/custom-url-serializer';
import { FluidraProSupportComponent } from './deeplink/fluidra-pro-support/fluidra-pro-support.component';

const routes: Routes = [{
  path: 'signin',
  component: SignInComponent,
  data: {
    title: 'Login Page'
  }
},{
  path: 'manage-account-info',
  canActivate: [AuthGuardService.engineerUser],
  component: DashboardComponent,
  data: {
    title: 'Account Details'
  }
 },{
  path: 'engineering',
  canActivate: [AuthGuardService.engineerUser],
  component: DeviceListComponent,
  data: {
    title: 'Device List'
  }
 },{
  path: 'register',
  canActivate: [AuthGuardService.engineerUser],
  component: RegisterDeviceComponent,
  data: {
    title: 'Register Device'
  }
 },
{
  path: 'owners-center',
  canActivate: [AuthGuardService],
  component: DashboardComponent,
  data: {
    title: 'Owners Center'
  }
 },{
  path: 'manage-account',
  canActivate: [AuthGuardService],
  component: ManageAccountComponent,
  data: {
    title: 'Manage Account'
  }
 },{
  path: 'add-location',
  canActivate: [AuthGuardService],
  component: AddLocationComponent,
  data: {
    title: 'Add Location'
  }
 },
 {
  path: 'static/privacypolicy',
  component: PrivacypolicyComponent,
  data: {
    title: 'Privacy Policy'
  }
},
{
  path: 'sign-up',
  component: SignUpComponent,
  data: {
    title: 'Register Page'
  }
},
{
  path: 'changePassword',
  component: ForgotUserPasswordComponent,
  data: {
    title: 'Forgot Password Page'
  }
},
{
  path: 'deleteAccount',
  component: DeleteAccountComponent,
  data: {
    title: 'Delete Account'
  }
},
{
  path: 'resetPassword',
  component: ForgotPasswordComponent,
  data: {
    title: 'Reset Password Page'
  }
},
{
  path:'fluidraProSupport',
  component:FluidraProSupportComponent,
  data:{
    title:'Fluidra Pro Support'
  }
},
{ path: '', component: RedirectComponent, pathMatch: 'full' },
// {  path: '**', redirectTo: 'signin'}
{ path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule],
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer }
  ]
})
export class AppRoutingModule { }
