import { Injectable } from '@angular/core';
import { ApiHandlerService } from './../core/util/api-handler.service';
import { ApiMethod, APP_MODEL_DATA } from '../core/const-data';
import { environment } from './../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  webTouchWindowsList: any = [];
  webTouchWindowsListRef: any = [];
  activeWebTouchWindowsList: any = [];
  selectedWebTouchWindow: any = [];
  checkWebTouchListInterval: any;
  webTouchWindowsListLocalStorage: any = [];
  sessionId : any;
  userInfo :any;
  userId = '';

  constructor(public router: Router, private http: ApiHandlerService, private authService: AuthService) {
    this.sessionId = this.authService.getLocalStoarge('sessionId');
    this.userInfo = JSON.parse(this.authService.getLocalStoarge('userInfo') || '{}');
    this.userId = this.userInfo.userId;

   }

  getDeviceData() {
    return this.http.requestCall('', '../../assets/100k.json', ApiMethod.GET, '', '', '');
  }

  getLocation() {
    let sessionId = this.authService.getLocalStoarge('sessionId');
    return this.http.requestCall(environment.apiUrl, '/users/' + sessionId + '/locations', ApiMethod.GET, '', '', '');
  }

  getDeviceList(sortingDetails: any){
    let sessionId = this.authService.getLocalStoarge('sessionId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8;'
    });
    return this.http.requestCall(environment.apiUrl, '/users/' + sessionId + '/devices', ApiMethod.POST, '', sortingDetails, headers);
  }

  removeNewPlatformDevice(serialNumber: string){ // 401 error
    let data = {
      "userId": this.authService.getLocalStoarge('sessionId')
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;'
    });
    return this.http.requestCall(environment.platformIoUrlV1,'/' + serialNumber + '/remove', ApiMethod.POST, '', data, headers);
  }

  removeLegacyDevice(serialNumber: string){ // works
    let data = {
      "userId": this.authService.getLocalStoarge('sessionId')
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;'
    });
    return this.http.requestCall(environment.apiUrl,'/device/' + serialNumber + '/delete', ApiMethod.POST, '', data, headers);
  }

  getAPIKey(key: string | undefined){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'API-KEY': environment.AmzXApiKey
    });
    return this.http.requestCall(environment.rubyApiUrl, '/secret/v1/list?keys=' + key, ApiMethod.GET, '', '', { headers: headers });
  }

  addNewPlatformDevice(deviceId: string, deviceData: any){   
    deviceData = JSON.stringify(deviceData);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8;'
    });
    return this.http.requestCall(environment.platformIoUrl, '/' + deviceId + '/add', ApiMethod.POST, '', deviceData, headers);
  }

  addLegacyDevice(deviceId: string,deviceData: any){   
    deviceData = JSON.stringify(deviceData);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8;'
    });
    return this.http.requestCall(environment.apiUrl, '/device/' + deviceId + '/add_device', ApiMethod.POST, '', deviceData, headers);
  }

  getDeviceStatus(serialNumber: string) {
    let sessionId = this.authService.getLocalStoarge('sessionId');
    let data = {
      "userId": sessionId
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8;'
    });
    return this.http.requestCall(environment.apiUrl, '/device/' + serialNumber + '/status', ApiMethod.POST, '', data, headers);
  }

  updateDeviceName(data:any,serialNumber: string){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8;'
    });
    return this.http.requestCall(environment.apiUrl, '/device/' + serialNumber + '/name', ApiMethod.POST, '', data, headers);
  }

  deleteDevice(serialNumber: string){
    let sessionId = this.authService.getLocalStoarge('sessionId');
    let data = {
      "userId": sessionId
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8;'
    });
    return this.http.requestCall(environment.apiUrl, '/device/' + serialNumber + '/delete', ApiMethod.POST, '', data, headers);
  }

  launchWebTouchClick(device: any, debug: string) {
    let name = device.Id;
    let base = environment.webTouch;
    let token = this.authService.getToken('idToken');
    let href = base + "/?actionID=" + device.touchLink + "&idToken=" + token + debug;
    let props = "height=540," + "width=860," + "toolbar=no," + "directories=no," + "status=no," + "menubar=no," + "scrollbars=no," + "resizable=no," + "modal=yes";
    this.webTouchWindowsListRef = JSON.parse(this.authService.getLocalStoarge('webTouchWindowsList')!);
    if (this.webTouchWindowsList.length > 0) {

      this.activeWebTouchWindowsList = this.webTouchWindowsList.filter((obj: any) => !obj.webTouchWindowRef.closed);
      this.webTouchWindowsList = this.activeWebTouchWindowsList;
      this.selectedWebTouchWindow = this.webTouchWindowsList.filter((obj: any) => obj.webTouchWindowName === name);
      if (this.selectedWebTouchWindow.length > 0) {
        if (this.selectedWebTouchWindow[0].debug !== debug) {
          // remove the old window reference and add the new window reference
          let objIndex = this.webTouchWindowsList.findIndex((obj: any) => obj.webTouchWindowName === name);
          this.webTouchWindowsList.splice(objIndex, 1);
          this.webTouchWindowsList.push({ "webTouchWindowRef": window.open(href, name, props), "webTouchWindowName": name, "deviceInfo": device, "debug": debug });
          // update webTouchWindowsList to local storage 
          this.webTouchWindowsListRef = this.webTouchWindowsList.map((obj: any) => {
            return { "webTouchWindowName": obj.webTouchWindowName, "deviceInfo": obj.deviceInfo, "debug": obj.debug };
          });
          this.authService.setLocalStorage("webTouchWindowsList", JSON.stringify(this.webTouchWindowsListRef));
        }
        else {
          window.open(href, name, props);
        }
      }
      else {
        this.webTouchWindowsList.push({ "webTouchWindowRef": window.open(href, name, props), "webTouchWindowName": name, "deviceInfo": device, "debug": debug });
        // update webTouchWindowsList to local storage
        this.webTouchWindowsListRef = this.webTouchWindowsList.map((obj: any) => {
          return { "webTouchWindowName": obj.webTouchWindowName, "deviceInfo": obj.deviceInfo, "debug": obj.debug };
        });
        this.authService.setLocalStorage("webTouchWindowsList", JSON.stringify(this.webTouchWindowsListRef));
      }
    }
    else {
      this.webTouchWindowsList.push({ "webTouchWindowRef": window.open(href, name, props), "webTouchWindowName": name, "deviceInfo": device, "debug": debug });
      // update webTouchWindowsList to local storage
      this.webTouchWindowsListRef = this.webTouchWindowsList.map((obj: any) => {
        return { "webTouchWindowName": obj.webTouchWindowName, "deviceInfo": obj.deviceInfo, "debug": obj.debug };
      });
      this.authService.setLocalStorage("webTouchWindowsList", JSON.stringify(this.webTouchWindowsListRef));
    }
    this.trackWebTouchLists();
  }

  trackWebTouchLists() {
    if (!this.checkWebTouchListInterval) {
      this.checkWebTouchListInterval = setInterval(() => {
        if (this.webTouchWindowsList.length > 0) {
          for (let i = 0; i < this.webTouchWindowsList.length; i++) {
            if (this.webTouchWindowsList[i].webTouchWindowRef && this.webTouchWindowsList[i].webTouchWindowRef.closed) {
              this.webTouchWindowsListLocalStorage = JSON.parse(this.authService.getLocalStoarge('webTouchWindowsList')!);
              if (this.webTouchWindowsListLocalStorage.length > 0) {
                let removeObjIndex = this.webTouchWindowsListLocalStorage.findIndex((obj: any) => obj.webTouchWindowName === this.webTouchWindowsList[i].webTouchWindowName);
                if (removeObjIndex !== -1) {
                  this.webTouchWindowsListLocalStorage.splice(removeObjIndex, 1);
                  this.authService.setLocalStorage("webTouchWindowsList", JSON.stringify(this.webTouchWindowsListLocalStorage));
                }
              }
              else if (this.checkWebTouchListInterval) {
                clearInterval(this.checkWebTouchListInterval);
                this.checkWebTouchListInterval = undefined;
              }
            }
          }
        }
      }, APP_MODEL_DATA.TRACK_WEBTOUCH_TIMER);
    }
  }

  addLocation(name:string,serialNumber: string){
    let sessionId = this.authService.getLocalStoarge('sessionId');
    let data = {
      "name" : name,
      "userId": sessionId
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8;'
    });
    return this.http.requestCall(environment.apiUrl, '/device/' + serialNumber + '/add_device', ApiMethod.POST, '', data, headers);
  }

  updateUserInfo(data:any){
    let sessionId = this.authService.getLocalStoarge('sessionId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8;'
    });
    return this.http.requestCall(environment.apiUrl, '/users/' + sessionId + '/save_personal', ApiMethod.POST, '', data, headers);
  }

  updateUserEmail(data:any){
    let sessionId = this.authService.getLocalStoarge('sessionId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8;'
    });
    return this.http.requestCall(environment.apiUrl, '/users/' + sessionId + '/change_email', ApiMethod.POST, '', data, headers);
  }

  updateUserPassword(data:any){
    let sessionId = this.authService.getLocalStoarge('sessionId');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8;'
    });
    return this.http.requestCall(environment.apiUrl, '/users/' + sessionId + '/change_password', ApiMethod.POST, '', data, headers);
  }

}
