import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit  {
  title!: string;
  content!: string;
  buttonsConfig: any;
  isHTML!:boolean
  onClose?: () => void;
  onShow?: () => void;


  constructor(public bsModalRef: BsModalRef) { 
    console.log(this.buttonsConfig)
  }

  ngOnInit() {if (this.onShow) {
    this.onShow();
  }
  }

  closeModal(): void {
    this.bsModalRef.hide();
    if (this.onClose) {
      this.onClose();
    }
  }
}
