import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { LoaderService } from '../util/loader.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent {
  userInfo:any;
  validToken:any;

  constructor(private router: Router,private authService: AuthService, private loaderService: LoaderService) {
    this.userInfo = JSON.parse(this.authService.getLocalStoarge('userInfo') || '{}');
    this.validToken = this.authService.isAuthenticated();
    
    const parts = location.href.split('/');
    const lastPart = parts[parts.length - 1];

    if (location.href.includes('.html') && !this.validToken) {
      const newRoute = location.href.replace('.html', '');
      console.log(newRoute);
      console.log(lastPart);
      //this.router.navigate([lastPart]);
      // window.open(newRoute, "_blank");
    }
    if (location.href.includes('/index.html#/') && this.validToken) {
      const newRoute = location.href.replace('/index.html#/', '/');
      this.router.navigate([lastPart]);
    }
  }

  goToPage(){
    if(this.authService.checkUserRole(this.userInfo)){
      this.router.navigate([ 'owners-center']);
    } else if (this.userInfo?.userLevel == 'dev'){
      this.router.navigate([ 'engineering']);
    }
    if(!this.validToken && location.href.includes('.html')){
      const newRoute = location.href.replace('.html', '');
      console.log(newRoute);
      window.open(newRoute, "_blank");
    }
    // this.loaderService.hide();
  }
}
