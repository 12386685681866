<div class="container mt-5">
    <p-panel header="{{'lang.heading_manage_acct' |translate}}">
      <div class="card text-center">
        <div class="card-header">
          <div class="row">
            <div class="col-md-2 text-start mt-1">{{'lang.owner_info'| translate}}</div>
            <span class="col-md-2 offset-md-8 text-end" role="button" (click)="editDetails()">
              <ng-container *ngIf="!loadEdit">  <i class="icon-iaqualink-edit" ></i> {{'lang.edit'| translate }} </ng-container> 
              <ng-container *ngIf="loadEdit">  <i class='icon-iaqualink-close'></i> {{'lang.cancel'| translate }}</ng-container>             
            </span>
          </div>
        </div>
        <div class="card-body">
              <form *ngIf="!loadEdit">

                <fieldset disabled class="text-start">
                  <div class="mb-3">
                    <label class="p-2" for="name" class="form-label">{{'lang.name'| translate}}</label>
                    <input type="text" id="name" class="form-control" name="fullName" [(ngModel)]="fullName">
                  </div>
                  <div class="mb-3">
                    <label class="p-2" for="email" class="form-label">{{'lang.email'| translate}}</label>
                    <input type="email" id="email" class="form-control" name="email" [(ngModel)]="email">
                  </div>
                  <div class="mb-3">
                    <label class="p-2" for="address" class="form-label">{{'lang.address'| translate}}</label>
                    <textarea type="text" id="address" class="form-control" name="address" [(ngModel)]="address" rows="4" cols="50"></textarea>
                  </div>
                </fieldset>
              </form>
            <accordion *ngIf="loadEdit">
              <form [formGroup]="userForm">
                <accordion-group [isOpen]="closePersonalDetails">
                  <button class="btn btn-link  btn-block justify-content-between d-flex w-100  shadow-none"
                        accordion-heading type="button">
                    <div class="pull-left float-left">{{'lang.edit_personal' |translate}}</div>
                    <!-- <span class="badge badge-secondary bg-warning float-right pull-right" *ngIf="closePersonalDetails">{{'lang.cancel' | translate}}</span> -->
                </button>
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-start"> 
                      <h5 class="bold fl-color p-2"> {{'lang.required'| translate}}</h5>                               
                      <div class="row mb-2">
                        <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                            <label class="p-2"><span>{{'lang.first_name' | translate}}</span><span class = "text-danger"> *</span></label>
                            <input class="form-control" formControlName="firstName" autocomplete="none"
                            [ngClass]="{ 'is-invalid': isFieldValid('firstName')}">
                            <div class="invalid-feedback"  *ngIf="isFieldValid('firstName')">
                                <span class="float-start" *ngIf="userFormControl['firstName'].errors?.['required']">{{'lang.first_name' | translate}} {{'lang.validation_required' | translate}}</span>
                                <span class="float-start" *ngIf="(userFormControl['firstName'].errors?.['maxlength'] || userFormControl['firstName'].errors?.['minlength']) && !userFormControl['firstName'].errors?.['pattern']">{{'lang.invalid_data' | translate}}</span>
                                <span class="float-start" *ngIf="userFormControl['firstName'].errors?.['pattern'] ">{{'lang.validation_whitespace' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                            <label class="p-2"><span>{{'lang.last_name' | translate}}</span><span class = "text-danger"> *</span></label>
                            <input class="form-control" formControlName="lastName" autocomplete="none"
                            [ngClass]="{ 'is-invalid': isFieldValid('lastName')}">
                            <div class="invalid-feedback" *ngIf="isFieldValid('lastName')">
                                <span class="float-start" *ngIf="userFormControl['lastName'].errors?.['required']">{{'lang.last_name' | translate}} {{'lang.validation_required' | translate}}</span>
                                <span class="float-start" *ngIf="( userFormControl['lastName'].errors?.['minlength'] || userFormControl['lastName'].errors?.['maxlength']) && !userFormControl['lastName'].errors?.['pattern']">{{'lang.invalid_data' | translate}}</span>
                                <span class="float-start" *ngIf="userFormControl['lastName'].errors?.['pattern'] ">{{'lang.validation_whitespace' | translate}}</span>
                            </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                          <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                              <label class="p-2"><span>{{'lang.address1' | translate}}</span><span class = "text-danger"> *</span></label>
                              <textarea class="form-control" formControlName="address1" autocomplete="none"
                              [ngClass]="{ 'is-invalid': isFieldValid('address1')}"></textarea>
                              <div class="invalid-feedback" *ngIf="isFieldValid('address1')">
                                  <span class="float-start" *ngIf="userFormControl['address1'].errors?.['required']">{{'lang.address1' | translate}} {{'lang.validation_required' | translate}}</span>
                                  <span class="float-start" *ngIf="userFormControl['address1'].errors?.['pattern']">{{'lang.validation_whitespace' | translate}}</span>
                              </div>
                          </div>
                          <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                              <label class="p-2">{{'lang.address2' | translate}}</label>
                              <textarea class="form-control" formControlName="address2" autocomplete="none"></textarea>
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                              <label class="p-2"><span>{{'lang.city' | translate}}</span><span class = "text-danger"> *</span></label>
                              <input class="form-control" formControlName="city" autocomplete="none"  
                              [ngClass]="{ 'is-invalid': isFieldValid('city')}">
                              <div class="invalid-feedback" *ngIf="isFieldValid('city')">
                                  <span class="float-start" *ngIf="userFormControl['city'].errors?.['required']">{{'lang.city' | translate}} {{'lang.validation_required' | translate}}</span>
                                  <span class="float-start" *ngIf="userFormControl['city'].errors?.['pattern']">{{'lang.validation_whitespace' | translate}}</span>
                              </div>
                          </div>
                          <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                              <label class="p-2"><span>{{'lang.state' | translate}}</span><span class = "text-danger"> *</span></label>
                              <input class="form-control" formControlName="state" autocomplete="none" 
                              [ngClass]="{ 'is-invalid': isFieldValid('state')}">
                              <div class="invalid-feedback" *ngIf="isFieldValid('state')">
                                  <span class="float-start" *ngIf="userFormControl['state'].errors?.['required']">{{'lang.state' | translate}} {{'lang.validation_required' | translate}}</span>
                                  <span class="float-start" *ngIf="userFormControl['state'].errors?.['pattern']">{{'lang.validation_whitespace' | translate}}</span>
                              </div>
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                              <label class="p-2"><span>{{'lang.postal_code' | translate}}</span><span class = "text-danger"> *</span></label>
                              <input class="form-control" formControlName="postal" autocomplete="none"
                              [ngClass]="{ 'is-invalid': isFieldValid('postal')}">
                              <div class="invalid-feedback" *ngIf="isFieldValid('postal')">
                                  <span class="float-start" *ngIf="userFormControl['postal'].errors?.['required']">{{'lang.postal_code' | translate}} {{'lang.validation_required' | translate}}</span>
                                  <span class="float-start" *ngIf="userFormControl['postal'].errors?.['pattern']">{{'lang.validation_numberOnly' | translate}}</span>
                              </div>
                          </div> 
                          <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                            <label class="p-2"><span>{{'lang.country' | translate}}</span><span class = "text-danger"> *</span></label>
                            <select class="form-control" formControlName="country"
                            [ngClass]="{ 'is-invalid': isFieldValid('country')}">
                                <option *ngFor="let country of countries" [ngValue]="country.code">{{country.display}}</option>
                            </select>
                            <div class="invalid-feedback" *ngIf="isFieldValid('country')">
                                <span class="float-start" *ngIf="userFormControl['country'].errors?.['required']">{{'lang.country' | translate}} {{'lang.validation_required' | translate}}</span>
                            </div>
                          </div>
                      </div>
                      <div class="row mb-2">
                          <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                              <label class="p-2"><span>{{'lang.telephone' | translate}}</span><span class = "text-danger"> *</span></label>
                              <input class="form-control" formControlName="telephone" autocomplete="none"
                              [ngClass]="{ 'is-invalid': isFieldValid('telephone')}">
                              <div class="invalid-feedback" *ngIf="isFieldValid('telephone')">
                                  <span class="float-start" *ngIf="userFormControl['telephone'].errors?.['required']">{{'lang.telephone' | translate}} {{'lang.validation_required' | translate}}</span>
                                  <span class="float-start" *ngIf="userFormControl['telephone'].errors?.['pattern']">{{'lang.validation_numberOnly' | translate}}</span>
                              </div>
                          </div>
                          <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6"></div>
                      </div>
                  </div>

                    <br>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                      <button class="btn btn-light btn-sm me-md-2" type="button" (click)="closePersonalDetails = !closePersonalDetails">{{'lang.cancel' | translate}}</button>
                      <button class="btn btn-warning btn-sm" type="button" (click)="savePersonalInfo();closePersonalDetails = !closePersonalDetails">{{'lang.save' | translate}}</button>
                  </div>
                </div>
                </accordion-group>
              </form>

              <form [formGroup]="emailForm">
              <accordion-group [isOpen]="closeEmailDetails">
                    <button class="btn btn-link btn-block justify-content-between d-flex w-100  shadow-none"
                        accordion-heading type="button">
                        <div class="pull-left float-left">{{'lang.change_email' |translate}}</div>
                    <!-- <span class="badge badge-secondary bg-warning float-right pull-right">{{'lang.cancel' | translate}}</span> -->
                  </button>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-start">
                    <h5 class="bold fl-color p-2"> {{'lang.required'| translate}}</h5>   
                                                   
                      <div class="row mb-2">
                        <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                            <label class="p-2"><span>{{'lang.email_address_new' | translate}}</span><span class = "text-danger"> *</span></label>
                            <input class="form-control" formControlName="newEmail" type="email"
                            [ngClass]="{ 'is-invalid': isEmailFieldValid('newEmail')}">
                            <div class="invalid-feedback"  *ngIf="isEmailFieldValid('newEmail')">
                                <span class="float-start" *ngIf="emailFormControl['newEmail'].errors?.['required']">{{'lang.email_address_new' | translate}} {{'lang.validation_required' | translate}}</span>
                                <span class="float-start" *ngIf="emailFormControl['newEmail'].errors?.['email'] || emailFormControl['newEmail'].errors?.['pattern']"> {{'lang.email_valid_error' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                            <label class="p-2"><span>{{'lang.email_address_confirm' | translate}}</span><span class = "text-danger"> *</span></label>
                            <input class="form-control" formControlName="confirmEmail" type="email" autocomplete="none"
                            [ngClass]="{ 'is-invalid': isEmailFieldValid('confirmEmail')}">
                            <div class="invalid-feedback"  *ngIf="isEmailFieldValid('confirmEmail')">
                                <span class="float-start" *ngIf="emailFormControl['confirmEmail'].errors?.['required']">{{'lang.email_address_confirm' | translate}} {{'lang.validation_required' | translate}}</span>
                                <span class="float-start" *ngIf="emailFormControl['confirmEmail'].errors?.['email']|| emailFormControl['confirmEmail'].errors?.['pattern']"> {{'lang.email_valid_error' | translate}}</span>
                                <span  class="float-start" *ngIf="emailMatch && !emailFormControl['confirmEmail'].errors?.['required'] && !emailFormControl['confirmEmail'].errors?.['pattern'] && !emailFormControl['confirmEmail'].errors?.['email']">{{'lang.tooltip_bad_email_confirm' |translate}}</span>
                            </div>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6  mb-6">
                            <label class="p-2"><span>{{'lang.current_pw' | translate}}</span><span class = "text-danger"> *</span></label>
                            <input class="form-control" formControlName="emailPw" type="password"
                            [ngClass]="{ 'is-invalid': isEmailFieldValid('emailPw')}">
                            <div class="invalid-feedback"  *ngIf="isEmailFieldValid('emailPw')">
                                <span class="float-start" *ngIf="emailFormControl['emailPw'].errors?.['required']">{{'lang.current_pw' | translate}} {{'lang.validation_required' | translate}}</span>
                            </div>
                        </div>
                        <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                        </div>
                      </div>
                      <br>
                      <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                        <button class="btn btn-light btn-sm me-md-2" type="button" (click)="resetEmailInfo();closeEmailDetails = !closeEmailDetails">{{'lang.cancel' | translate}}</button>
                        <button class="btn btn-warning btn-sm" type="button" (click)="saveEmailInfo();closeEmailDetails = !closeEmailDetails">{{'lang.save' | translate}}</button>
                    </div>
                  </div>
                </div>
              </accordion-group>
              </form>

              <form [formGroup]="resetPassworForm">
              <accordion-group [isOpen]="closeResetPassword">
                <button class="btn btn-link btn-block justify-content-between d-flex w-100  shadow-none"
                    accordion-heading type="button">
                    <div class="pull-left float-left">{{'lang.change_password' |translate}}</div>
                    <!-- <span class="badge badge-secondary bg-warning float-right pull-right">{{'lang.cancel' | translate}}</span> -->
                  </button>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-start">
                    <h5 class="bold fl-color p-2"> {{'lang.required'| translate}}</h5>   
                        <div class="row mb-3">
                            <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 col-md-6">
                                <label class="p-2"><span>{{'lang.new_pw' | translate}}</span><span class = "text-danger"> *</span></label>
                                <input class="form-control" type="password" formControlName="newPw" autocomplete="none"  
                                [ngClass]="{ 'is-invalid': isPassowrdFieldValid('newPw')}">
                                <div class="float-start invalid-feedback"*ngIf="isPassowrdFieldValid('newPw')">
                                    <span class="float-start" *ngIf="resetFormControl['newPw'].errors?.['required']">{{'lang.new_pw' | translate}} {{'lang.validation_required' | translate}} </span>
                                    <span *ngIf="resetFormControl['newPw'].errors && !resetFormControl['newPw'].errors?.['required']">{{'lang.tooltip_bad_pw' | translate}}</span>
                                </div>
                            </div>
                            <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 col-md-6">
                                <label class="p-2"><span>{{'lang.password_new_confirm' | translate}}</span><span class = "text-danger"> *</span></label>
                                <input class="form-control" type="password" formControlName="confirmPw" autocomplete="none" 
                                [ngClass]="{ 'is-invalid': isPassowrdFieldValid('confirmPw')}">
                                <div class="invalid-feedback" *ngIf="isPassowrdFieldValid('confirmPw')">
                                    <span class="float-start" *ngIf="resetFormControl['confirmPw'].errors?.['required']">{{'lang.password_new_confirm' | translate}} {{'lang.validation_required' | translate}}</span>
                                    <span class="float-start" *ngIf="passwordMatch && !resetFormControl['confirmPw'].errors?.['required']">{{'lang.tooltip_bad_pw_confirm' | translate}}</span>
                                </div> 
                            </div>
                        </div>
                      
                      <div class="row mb-3">
                          <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 col-md-6">
                              <label class="p-2"><span>{{'lang.current_pw' | translate}}</span><span class = "text-danger"> *</span></label>
                              <input class="form-control" type="password" formControlName="pw" autocomplete="none"  
                              [ngClass]="{ 'is-invalid': isPassowrdFieldValid('pw')}">
                              <div class="float-start invalid-feedback"*ngIf="isPassowrdFieldValid('pw')">
                                  <span class="float-start" *ngIf="resetFormControl['pw'].errors?.['required']">{{'lang.current_pw' | translate}} {{'lang.validation_required' | translate}} </span>
                              </div>
                          </div>
                          <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 mb-6">
                          </div>
                    </div>
                    <br>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                      <button class="btn btn-light btn-sm me-md-2" type="button" (click)="resetPasswordInfo();closeResetPassword = !closeResetPassword">{{'lang.cancel' | translate}}</button>
                      <button class="btn btn-warning btn-sm" type="button" (click)="saveResetPasswordlInfo();closeResetPassword = !closeResetPassword">{{'lang.save' | translate}}</button>
                  </div>

                  </div>
                </div>
              </accordion-group>
              </form>

            </accordion>
      
        </div>
      </div>
    </p-panel>
  </div>