import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { interval, throwError, of, fromEvent } from 'rxjs';
import { catchError, retryWhen, delay, switchMap } from 'rxjs/operators';
import { LoaderService } from './util/loader.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private readonly refreshInterval = 3540000; // 3600000mm = 3600 seconds = 1 hour in milliseconds
  // private readonly refreshInterval = 6000; // 3600000mm = 3600 seconds = 1 hour in milliseconds

  private refreshIntervalId: any = undefined; // Initialize as undefined;

  constructor(private loaderService: LoaderService, private authService: AuthService) {
    // Listen for online status changes to retry refresh when the network is restored
    fromEvent(window, 'online').subscribe(() => {
      console.log('Network reconnected. Attempting token refresh...');
      const refreshToken = this.authService.getToken('refreshToken') || this.authService.getLocalStoarge('refreshToken');
      const expireInterval = this.authService.getLocalStoarge('expireInterval');
      if (refreshToken && expireInterval) {
        this.refreshToken(refreshToken, Number(expireInterval));
      }
    });
   }

  /**
   * Starts the periodic refresh token process every 1 hour
   */
  startTokenRefresh() {
    const refreshToken = this.authService.getToken('refreshToken') || this.authService.getLocalStoarge('refreshToken');
    const expireInterval = this.authService.getLocalStoarge('expireInterval');

    if (refreshToken && expireInterval && !this.refreshIntervalId) {
      this.refreshToken(refreshToken, Number(expireInterval));
      console.log('Initial token refresh on page load at:', new Date().toLocaleTimeString());
      
      // Set up an interval to refresh the token every hour
      this.refreshIntervalId = setInterval(() => {
        // Check for online status before attempting to refresh
        if (navigator.onLine) {
          this.refreshToken(refreshToken, Number(expireInterval));
          console.log('Periodic token refresh at:', new Date().toLocaleTimeString());
        } else {
          console.warn('Network is offline. Skipping token refresh.');
        }
      }, this.refreshInterval);
    }
  }

  /**
   * Calls the refresh token API and updates the tokens
   * @param refreshToken The refresh token to use for API call
   * @param expireInterval Expiration time in seconds
   */
  private refreshToken(refreshToken: string, expireInterval: number) {
    const userInfoString = this.authService.getLocalStoarge('userInfo');
    const parsedUserInfo = userInfoString ? JSON.parse(userInfoString) : null;
    const email = parsedUserInfo?.email;

    if (!email || !refreshToken) {
      console.error('Email or refresh token is missing.');
      return;
    }

    this.authService.refreshToken(email, refreshToken)?.pipe(
      catchError(error => {
        this.loaderService.hide();
        console.error('Error refreshing token:', error);
        return throwError(error);
      })
    ).subscribe((data: any) => {
      const idToken = data?.userPoolOAuth?.IdToken;
      if (!idToken) {
        console.error('IdToken is missing from the response');
        return;
      }

      // Set cookies for idToken and refreshToken
      const _now = new Date();
      _now.setTime(_now.getTime() + expireInterval * 1000);
      const expires = this.authService.add_year(new Date(), expireInterval).toString();
      document.cookie = `idToken=${idToken}; expires=${_now.toUTCString()}; path=/;Priority=High;secure;`;
      // document.cookie = `refreshToken=${refreshToken}; expires=${expires}; path=/;Priority=High;secure;`;
      document.cookie = `refreshToken=${refreshToken}; expires=${_now.toUTCString()}; path=/;Priority=High;secure;`;

      this.loaderService.hide();
      console.log('Token refreshed and cookies updated successfully.');

    });
  }
}
