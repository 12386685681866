<!-- <p>register-device works!</p> -->
<div class="container mt-5">
    <p-panel header="{{'lang.register_device_for' |translate}}">
                <form [formGroup]="deviceForm">
                    <fieldset class="text-start">
                        <div class="mb-3 row">
                            <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 ">
                                <label class="p-1"><span>{{'lang.device_serial_number' | translate}}</span><span class = "text-danger"> *</span></label>
                                <input class="form-control" formControlName="deviceNumber" autocomplete="none"
                                [ngClass]="{ 'is-invalid': isFieldValid('deviceNumber')}">
                                <div class="invalid-feedback"  *ngIf="isFieldValid('deviceNumber')">
                                    <span class="float-start" *ngIf="deviceFormControl['deviceNumber'].errors?.['required']">{{'lang.device_serial_number' | translate}} {{'lang.validation_required' | translate}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6">
                                <label class="p-1"><span>{{'lang.name' | translate}}</span><span class = "text-danger"> *</span></label>
                                <input class="form-control" formControlName="name" autocomplete="none"
                                [ngClass]="{ 'is-invalid': isFieldValid('name')}">
                                <div class="invalid-feedback"  *ngIf="isFieldValid('name')">
                                    <span class="float-start" *ngIf="deviceFormControl['name'].errors?.['required']">{{'lang.name' | translate}} {{'lang.validation_required' | translate}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 row">
                            <div class="form-group col-sm-12 col-md-6 col-lg-6 col-xs-6 m-6">
                                <label class="p-1"><span>{{'lang.type' | translate}}</span><span class = "text-danger"> *</span></label>
                                <!-- <input class="form-control" formControlName="type" autocomplete="none"
                                [ngClass]="{ 'is-invalid': isFieldValid('type')}"> -->
                                <select class="form-control" formControlName="type"
                                [ngClass]="{ 'is-invalid': isFieldValid('type')}">
                                    <option *ngFor="let deviceType of devicetypes" [ngValue]="deviceType.Id">{{deviceType.Name}}</option>
                                </select>
                                <div class="invalid-feedback"  *ngIf="isFieldValid('type')">
                                    <span class="float-start" *ngIf="deviceFormControl['type'].errors?.['required']">{{'lang.type' | translate}} {{'lang.validation_required' | translate}}</span>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div class="d-grid gap-2 d-md-flex justify-content-md-start">
                      <button class="btn btn-light btn-sm me-md-2" type="button" (click)="cancel()">{{'lang.cancel' | translate}}</button>
                      <button class="btn btn-warning btn-sm" type="button" (click)="registerDevice()">{{'lang.save' | translate}}</button>
                  </div>
                </form>
    </p-panel>
</div>