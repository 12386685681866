import { Component, OnInit } from '@angular/core';
import { APP_MODEL_DATA } from '../../core/const-data';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../dashboard/user.service';
import { AuthService } from '../../auth/auth.service';
import { ToastService } from '../../core/util/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../core/util/loader.service';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';


@Component({
  selector: 'app-register-device',
  templateUrl: './register-device.component.html',
  styleUrls: ['./register-device.component.css']
})
export class RegisterDeviceComponent implements OnInit {
  deviceForm!: FormGroup;
  devicetypes = APP_MODEL_DATA.DEVICE_TYPES;
  userInfo :any;
  userId = '';
  constructor(private router: Router,private userService: UserService, private authService: AuthService, private formBuilder: FormBuilder,private toastService: ToastService,private translateService: TranslateService, private loaderService: LoaderService) {

  }

  ngOnInit(): void {
    this.devicetypes = this.devicetypes.filter((item) => !APP_MODEL_DATA.NEW_DEVICES_HPM_HPC_EXO_ZS500.includes(item.Id));
    this.formInit();
  }

  registerDevice(){
    if(!this.deviceForm.valid){
      this.validateAllFormFields(this.deviceForm);
    } else {
      let isNewPlatformDevice = APP_MODEL_DATA.NEW_PLATFORM_DEVICES.includes(this.deviceForm.value.type);
      let serialnumber = this.deviceForm.value.deviceNumber.toUpperCase();
      if(isNewPlatformDevice){
        var String_to_sign = serialnumber + ',' + this.authService.getLocalStoarge("rubyUserId");
        let key = APP_MODEL_DATA.PLATFORM_DEVICES_V2_APIKEYANDSECRET;
        this.userService.getAPIKey(key)?.subscribe((details:any)=>{
          // {"deviceType":"vortrax","name":"VAX234","signature":"e2d286efd74d6da05c9ef82d88d156680dca13b0","userId":"741157"}
          if(details.secret_values[0].secret_key == key){
            let getsecret_value = details.secret_values[0].secret_value.split('|');
            this.userInfo = JSON.parse(this.authService.getLocalStoarge('userInfo') || '{}');
            this.userId = this.userInfo.userId;
            let deviceBody = {
              deviceType: this.deviceForm.value.type,
              name: this.deviceForm.value.name,
              signature: CryptoJS.HmacSHA1(String_to_sign, getsecret_value[1]).
                toString(CryptoJS.enc.Hex),
              userId: this.userId  
            };
            this.userService.addNewPlatformDevice(serialnumber,deviceBody)?.subscribe((deviceDetails:any)=>{
              console.log(deviceDetails);
              this.toastService.showSuccess(this.translateService.instant('lang.device_registered'),this.translateService.instant('lang.modal_saving_success'));
              this.cancel();
            });
          }
        })
      }
      else{
        let device = {
          name: this.deviceForm.value.name,
          userId: this.authService.getLocalStoarge('sessionId'),
          deviceType: this.deviceForm.value.type
        };
        this.userService.addLegacyDevice(serialnumber,device)?.subscribe((deviceDetails:any)=>{
          console.log(deviceDetails);
          this.toastService.showSuccess(this.translateService.instant('lang.device_registered'),this.translateService.instant('lang.modal_saving_success'));
          this.cancel();
        });
      }
    }
  }

  cancel(){
    this.router.navigate(["engineering"]);
  }

  formInit(){
    this.deviceForm = this.formBuilder.group({     
      deviceNumber: ['', Validators.required],
      name: ['', Validators.required],
      type: ['', Validators.required]
    })
  }

  isFieldValid(field: string) {
    return this.deviceForm?.get(field)?.invalid && this.deviceForm.get(field)?.touched;
  }

  get deviceFormControl():{ [key: string]: AbstractControl } {
    return this.deviceForm.controls;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
       const control = formGroup.get(field);
      if (control instanceof FormControl) {
         control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

}
