import { AfterViewInit, Component } from '@angular/core';
import { UtilService } from '../../core/util/util.service';

@Component({
  selector: 'app-fluidra-pro-support',
  templateUrl: './fluidra-pro-support.component.html',
  styleUrls: ['./fluidra-pro-support.component.css']
})
export class FluidraProSupportComponent implements AfterViewInit{

  constructor(private utilService: UtilService) {
    this.utilService.checkLanguageConstraintForSignIn();
  }

  ngAfterViewInit(): void {
    this.checkAppInstallation();
  }

  
  checkAppInstallation() {
    const appScheme = 'fluirdaProApp://'; // Your custom URL scheme
    const fallbackUrlAndroid = 'https://play.google.com/store/apps/details?id=com.zodiac.fluidrapro';
    const fallbackUrlIOS = 'https://apps.apple.com/app/fluidra-pro/id1538621105';

    let isAppInstalled = false; // Initialize as false

    // Attempt to open the app using the custom URL scheme
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = appScheme;
    document.body.appendChild(iframe);

    // Timeout to detect if app is installed or fallback to store
    setTimeout(() => {
      // If the app wasn't opened within the timeout, assume it's not installed
      if (!isAppInstalled) {
        // Detect device type and redirect to the appropriate store
        if (navigator.userAgent.includes('Android')) {
          window.location.href = fallbackUrlAndroid;
        } else if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')) {
          window.location.href = fallbackUrlIOS;
        }
      } else {
        console.log('The app is installed, proceed to show content.');
      }
    }, 1500); // Adjust timeout as needed

    // Track if the app was successfully opened
    window.onblur = () => {
      isAppInstalled = true; // App is installed if the user was taken away from the browser
    };
}


}
