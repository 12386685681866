import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { AuthService } from '../../auth/auth.service';
import { APP_MODEL_DATA } from '../../core/const-data'
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ToastService } from '../../core/util/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../../core/util/loader.service';
@Component({
  selector: 'app-manage-account',
  templateUrl: './manage-account.component.html',
  styleUrls: ['./manage-account.component.css']
})
export class ManageAccountComponent implements OnInit {
  userForm!: FormGroup;
  emailForm !:FormGroup;
  resetPassworForm !:FormGroup;

  NoWhitespaceStartRegExp = /^(\s+\S+\s*)*(?!\s).*$/
  digitsOnlyRegExp = new RegExp(APP_MODEL_DATA.DIGITS_ONLY_REGX);
  regularExpression = new RegExp(String.raw`^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\?;,./{}|\":<>\[\]\\'~_\`])[a-zA-Z0-9!@#$%^&*()\?;,./{}|\":<>\[\]\\'~_\`]{${environment.passwordMinLength},${environment.passwordMaxLength}}$`);
  passwordMatch = false;
  emailMatch = false;
  countries = APP_MODEL_DATA.COUNTRIES;

  loadData = false;
  userDetails: any;
  loadEdit = false;

  fullName = '';
  email = '';
  address = '';

  mergedUserEmail: any;
  mergedUserPassword: any;

  closePersonalDetails :boolean = false;
  closeEmailDetails: boolean = false;
  closeResetPassword: boolean = false;

  constructor(private userService: UserService, private authService: AuthService, private formBuilder: FormBuilder,private toastService: ToastService,private translateService: TranslateService, private loaderService: LoaderService) {
    this.userDetails = JSON.parse(this.authService.getLocalStoarge('userInfo') || '{}');
    this.userInfo();
  }

  userInfo(){
    this.fullName = this.userDetails.firstName + ' ' + this.userDetails.lastName;
    this.email = this.userDetails.email;
    let country = APP_MODEL_DATA.COUNTRIES.find(country=>{
      return country.code == this.userDetails.country ? country.display : '';
    });
    this.address = this.userDetails.address1 + '\n' + this.userDetails?.address2 + '\n' + this.userDetails?.city + ' ' + this.userDetails?.state + ' ' + this.userDetails?.postal + '\n' + country?.display;
  }

  ngOnInit(): void {
    this.formInit();
    this.emailformInit();
    this.resetformInit();
    this.userForm.setValue(this.userDetails);
    //console.log(this.userForm.value)
  }


  formInit(){
    this.userForm = this.formBuilder.group({
      firstName:['',[Validators.required,Validators.minLength(3),Validators.maxLength(250),Validators.pattern(this.NoWhitespaceStartRegExp)]],
      lastName:['',[Validators.required,Validators.minLength(3),Validators.maxLength(250),Validators.pattern(this.NoWhitespaceStartRegExp)]],
      email:[null,  [Validators.required,Validators.email,Validators.pattern(this.NoWhitespaceStartRegExp)]],
      address1:[null, [Validators.required,Validators.pattern(this.NoWhitespaceStartRegExp)]],
      address2:[],
      city:[null, [Validators.required,Validators.pattern(this.NoWhitespaceStartRegExp)]],
      state:[null, [Validators.required,Validators.pattern(this.NoWhitespaceStartRegExp)]],
      postal:[null, [Validators.compose([Validators.required, Validators.pattern(this.digitsOnlyRegExp)])]],
      country: [null, Validators.required],
      telephone:[null,[Validators.required,Validators.pattern(this.NoWhitespaceStartRegExp),Validators.pattern(this.digitsOnlyRegExp)]],
      Id:[''],
      userId:[''],
      userLevel: ['user'],
      role: [''],
      disabled: [0],
      timezone:[''],        
      userPermissions: [null],
    });
  }

  emailformInit(){
    this.emailForm = this.formBuilder.group({
      confirmEmail:['',  [Validators.required,Validators.email,Validators.pattern(this.NoWhitespaceStartRegExp)]],
      newEmail:['',  [Validators.required,Validators.email,Validators.pattern(this.NoWhitespaceStartRegExp)]],
      emailPw:['',Validators.required],
    });
  }
  
  resetformInit(){
    this.resetPassworForm = this.formBuilder.group({
      newPw:[null,Validators.required],
      pw:[null,Validators.required],
      confirmPw:[null,Validators.required]
    });
    this.resetPassworForm.controls["newPw"].setValidators([Validators.required,Validators.pattern(this.regularExpression)]);
    this.resetPassworForm.controls["confirmPw"].setValidators(Validators.required);
  }

  editDetails(){
    this.loadEdit = !this.loadEdit; 
  }

  isPassowrdFieldValid(field: string){
    if(field == 'confirmPw' && this.resetPassworForm?.get('confirmPw')?.touched && this.resetPassworForm?.get('newPw')?.value){
      return this.passwordMatch = this.resetPassworForm?.get('confirmPw')?.value !== this.resetPassworForm?.get('newPw')?.value ? true : false;
    }
    return this.resetPassworForm?.get(field)?.invalid && this.resetPassworForm?.get(field)?.touched;
  }

  isEmailFieldValid(field: string){
    if(field == 'confirmEmail' && this.emailForm?.get('confirmEmail')?.touched && this.emailForm?.get('newEmail')?.value){
      return this.emailMatch = this.emailForm?.get('confirmEmail')?.value !== this.emailForm?.get('newEmail')?.value ? true : false;
    }
    return this.emailForm?.get(field)?.invalid && this.emailForm?.get(field)?.touched;
  }

  isFieldValid(field: string) {
    // if(field == 'confirmPw' && this.userForm?.get('confirmPw')?.touched){
    //    return this.passwordMatch = this.userForm?.get('confirmPw')?.value !== this.userForm?.get('newPw')?.value ? true : false;
    // }
    return this.userForm?.get(field)?.invalid && this.userForm.get(field)?.touched;
  }

  // function to validate form instances
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
       const control = formGroup.get(field);
      if (control instanceof FormControl) {
         control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  
  get userFormControl(): { [key: string]: AbstractControl } {
    return this.userForm.controls;
  }

  get emailFormControl(): { [key: string]: AbstractControl } {
    return this.emailForm.controls;
  }

  get resetFormControl(): { [key: string]: AbstractControl } {
    return this.resetPassworForm.controls;
  }

  userAccount(){
    this.userDetails = '';
    const sessionId = this.authService.getLocalStoarge('sessionId')|| ''
    this.authService.userAccount(sessionId)?.subscribe((userInfo:any)=>{
      this.authService.setLocalStorage('userInfo',JSON.stringify(userInfo.account));
      this.userDetails = userInfo.account;
      this.userInfo();
      this.loadEdit = false;
      this.loaderService.hide();
      // setTimeout(() => {
      // }, 2000);
    },
    (error) => {
      console.log(error.error);
     // this.loaderService.hide();
    })
  }
 
  savePersonalInfo(){
    if(!this.userForm.valid){
      this.validateAllFormFields(this.userForm);
    } else {
      // console.log(this.userForm.value)
      this.userService.updateUserInfo(this.userForm.value)?.subscribe(
        (response:any)=>{
          // console.log('response',response);
          this.userAccount();
          this.toastService.showSuccess('', this.translateService.instant('lang.modal_saving_success'));
         // this.reset();
        },
        (error:any)=>{
          console.log('error',error)
          // this.reset();
        }
       )
    }
   // this.closePersonalDetails = true;
  }

  saveEmailInfo(){
    if(this.emailForm.valid){
      const form1 = this.emailForm.value; 
      const form2 = this.userForm.value;
      this.mergedUserEmail = {...form1, ...form2};
      this.mergedUserEmail.email = this.mergedUserEmail.newEmail;
      this.mergedUserEmail.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      delete this.mergedUserEmail.newEmail;
      this.userService.updateUserEmail(this.mergedUserEmail)?.subscribe(
        (response:any)=>{
          console.log('response',response);
          this.loaderService.hide();
          if(response?.messages[0]?.text == "email_exists_error"){
            this.toastService.showError(this.translateService.instant('lang.email_exists_error'), this.translateService.instant('lang.error'));
          } else {
            this.toastService.showSuccess('', this.translateService.instant('lang.modal_saving_success'));
            this.authService.clearStorage();
          }
          // update account info
         // this.reset();
        },
        (error:any)=>{
          console.log('error',error)
          // this.reset();
        }
       )
    } else {
      this.validateAllFormFields(this.emailForm);
    }
  }

  resetEmailInfo(){
    this.emailForm.reset();
    // this.closeEmailDetails = false;
  }



  saveResetPasswordlInfo(){
    if(this.resetPassworForm.valid){
      const form1 = this.userForm.value; 
      const form2 = this.resetPassworForm.value;
      this.mergedUserPassword = {...form1, ...form2};
      // console.log(this.mergedUserPassword)
      this.userService.updateUserPassword(this.mergedUserPassword)?.subscribe(
        (response:any)=>{
          this.loaderService.hide();
          // console.log('response',response);
          this.authService.clearStorage();
          // logout
        },
        (error:any)=>{
          console.log('error',error)
        }
       )
    } else {
      this.validateAllFormFields(this.resetPassworForm);
    }
  }

  resetPasswordInfo(){
    this.resetPassworForm.reset();
    // this.closeResetPassword = false;
  }

}
