import { Component } from '@angular/core';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  isEngineeringUser = false;
  constructor(private authService: AuthService) { 
    const userInfo = JSON.parse(authService.getLocalStoarge('userInfo') || '{}');
    this.isEngineeringUser = userInfo?.userLevel == 'dev' ? true : false;
  }
  signOut() {
    this.authService.clearStorage();
  }
}
